import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NotificationService } from '../../../services/notification.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '../../../services/http.service';
import { Subscription } from 'rxjs';
import { MaterialSelect2Service } from '../../../modules/@material-input/component/material-select2.service';
import { debounceTime } from 'rxjs/operators';
import {BreadcrumbsService} from '../../../shared/breadcrumbs/services';


@Component({
  selector: 'app,yamap-entitie-edit-common',
  templateUrl: './entity-edit-common.component.html',
  styleUrls: ['./entity-edit-common.component.scss']
})
export class EntityEditCommonComponent implements OnInit {

  public data: any = null;
  public phone_main: any = null;
  public phones: Array<any> = [];
  public types: Array<any> = [];
  public owners: Array<any> = [];
  public managers: Array<any> = [];
  public forms: Array<any> = [];
  public formGroup: UntypedFormGroup;
  public entityId = null;
  private paramSubscribe: Subscription;
  private dataSubscribe: Subscription;
  public loading = true;

  constructor(public activeRoute: ActivatedRoute, public fb: UntypedFormBuilder, public http: HttpClient,
              public notify: NotificationService,
              public select2Service: MaterialSelect2Service, private b: BreadcrumbsService) {
  }

  ngOnInit() {
    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/entity/list', caption: 'Юридические лица'},
    ]);
    this.data = this.activeRoute.snapshot.data['data'];
    this.formGroup = this.fb.group({
      'organisation_author_fio': [{value: this.data['organisation_author_fio'], disabled: true}],
      'organisation_author_id': [this.data['organisation_author_id']],
      'organisation_create_date': [{value: this.data['organisation_create_date'], disabled: true}],
      'organisation_phones': [this.data['organisation_phones']],
      'phone_new': [''],
      'organisation_yandex_id': [(this.data['organisation_yandex_id']) ? '' + this.data['organisation_yandex_id'] : ''],
      'organisation_form_id': [(this.data['organisation_form_id']) ? '' + this.data['organisation_form_id'] : ''],
      'organisation_type_id': [(this.data['organisation_type_id']) ? '' + this.data['organisation_type_id'] : ''],
      'organisation_caption': [this.data['organisation_caption']],
      'organisation_socrachcaption': [this.data['organisation_socrachcaption']],
      'organisation_komm_caption': [this.data['organisation_komm_caption']],
      'organisation_address': [this.data['organisation_address']],
      'organisation_fact_addr': [this.data['organisation_fact_addr']],
      'organisation_post_address': [this.data['organisation_post_address']],
      'organisation_email': [this.data['organisation_email']],
      'organisation_rekl_email': [this.data['organisation_rekl_email']],
      'organisation_rekl_tel': [this.data['organisation_rekl_tel']],
      'organisation_website': [this.data['organisation_website']],
      'organisation_gendir_fio': [this.data['organisation_gendir_fio']],
      'organisation_dolj_ryk': [this.data['organisation_dolj_ryk']],
      'organisation_osn_dolj': [this.data['organisation_osn_dolj']],
      'organisation_glbuh_fio': [this.data['organisation_glbuh_fio']],
      'organisation_notes': [this.data['organisation_notes']],
      'organisation_owner_id': [(this.data['organisation_owner_id']) ? '' + this.data['organisation_owner_id'] : ''],
      'organisation_manager_id': [(this.data['organisation_manager_id']) ? '' + this.data['organisation_manager_id'] : ''],
      'organisation_overall_unique_is': [!!this.data['organisation_overall_unique_is']],
      'organisation_not_statistic_is': [!!this.data['organisation_not_statistic_is']],
    });

    this.paramSubscribe = this.activeRoute.parent.paramMap.subscribe((params) => {
      this.entityId = this.activeRoute.parent.snapshot.params['id'];
    });

    this.dataSubscribe = this.activeRoute.data.subscribe((data) => {
      if (this.loading) {
        this.types = data['types'];
        this.owners = data['owners'];
        this.forms = data['forms'];
        this.managers = data['managers'];
        this.loading = !this.loading;
      } else {
        this.data = data['data'];
        this.pathForm();
      }
      this.phones = this.data['organisation_phones'];
      this.phones.forEach((phone) => {
        if (phone['phone_is_main']) {
          this.phone_main = phone['phone_id'];
        }
      });
    });
  }

  pathForm() {
    this.formGroup.patchValue({
      'organisation_author_fio': this.data['organisation_author_fio'],
      'organisation_author_id': this.data['organisation_author_id'],
      'organisation_create_date': this.data['organisation_create_date'],
      'organisation_phones': this.data['organisation_phones'],
      'phone_new': '',
      'organisation_yandex_id': '' + this.data['organisation_yandex_id'],
      'organisation_form_id': '' + this.data['organisation_form_id'],
      'organisation_type_id': '' + this.data['organisation_type_id'],
      'organisation_caption': this.data['organisation_caption'],
      'organisation_socrachcaption': this.data['organisation_socrachcaption'],
      'organisation_komm_caption': this.data['organisation_komm_caption'],
      'organisation_address': this.data['organisation_address'],
      'organisation_fact_addr': this.data['organisation_fact_addr'],
      'organisation_post_address': this.data['organisation_post_address'],
      'organisation_email': this.data['organisation_email'],
      'organisation_rekl_email': this.data['organisation_rekl_email'],
      'organisation_rekl_tel': this.data['organisation_rekl_tel'],
      'organisation_website': this.data['organisation_website'],
      'organisation_gendir_fio': this.data['organisation_gendir_fio'],
      'organisation_dolj_ryk': this.data['organisation_dolj_ryk'],
      'organisation_osn_dolj': this.data['organisation_osn_dolj'],
      'organisation_glbuh_fio': this.data['organisation_glbuh_fio'],
      'organisation_notes': this.data['organisation_notes'],
      'organisation_owner_id': this.data['organisation_owner_id'],
      'organisation_manager_id': this.data['organisation_manager_id'],
      'organisation_overall_unique_is': this.data['organisation_overall_unique_is'],
      'organisation_not_statistic_is': this.data['organisation_not_statistic_is'],
    });
  }

  saveForm() {
    if (!this.formGroup.valid) {
      this.onError('Заполните обязательные поля');
      return false;
    }
    const post_data = {
      'organisation_yandex_id': this.formGroup.value.organisation_yandex_id ? this.formGroup.value.organisation_yandex_id : null,
      'organisation_form_id': this.formGroup.value.organisation_form_id ? this.formGroup.value.organisation_form_id : null,
      'organisation_type_id': this.formGroup.value.organisation_type_id ? this.formGroup.value.organisation_type_id : null,
      'organisation_caption': this.formGroup.value.organisation_caption,
      'organisation_socrachcaption': this.formGroup.value.organisation_socrachcaption,
      'organisation_komm_caption': this.formGroup.value.organisation_komm_caption,
      'organisation_address': this.formGroup.value.organisation_address,
      'organisation_fact_addr': this.formGroup.value.organisation_fact_addr,
      'organisation_post_address': this.formGroup.value.organisation_post_address,
      'organisation_email': this.formGroup.value.organisation_email,
      'organisation_rekl_email': this.formGroup.value.organisation_rekl_email,
      'organisation_website': this.formGroup.value.organisation_website,
      'organisation_gendir_fio': this.formGroup.value.organisation_gendir_fio,
      'organisation_dolj_ryk': this.formGroup.value.organisation_dolj_ryk,
      'organisation_osn_dolj': this.formGroup.value.organisation_osn_dolj,
      'organisation_glbuh_fio': this.formGroup.value.organisation_glbuh_fio,
      'organisation_owner_id': this.formGroup.value.organisation_owner_id ? this.formGroup.value.organisation_owner_id : null,
      'organisation_notes': this.formGroup.value.organisation_notes,
      'organisation_overall_unique_is': this.formGroup.value.organisation_overall_unique_is,
      'organisation_not_statistic_is': this.formGroup.value.organisation_not_statistic_is,
    };
    if (this.data['organisation_create_date']) {
      post_data['organisation_manager_id'] = this.formGroup.value.organisation_manager_id ? this.formGroup.value.organisation_manager_id : null;
    }
    this.http.post('Organisation.save_organisation_common',
      {'organisation_id': +this.entityId, 'data': post_data}
    ).subscribe(
      (response) => {
        this.activeRoute.parent.snapshot.data['about']['organisation_type_id'] = this.formGroup.value.organisation_type_id;
        this.onSuccess(response);
      },
      (error) => this.onError(error)
    );
  }

  phoneDelete(phone: any) {
    this.http.post('Phones.del_phone',
      {'phone_id': phone['phone_id']}
    ).subscribe(
      (response) => {
        this.phones.splice(this.phones.indexOf(phone), 1);
      },
      _ => {}
    );
  }

  phoneMain(phone_id: any) {
    this.http.post('Phones.save_is_main',
      {'phone_id': phone_id}
    ).subscribe(
      (response) => {
        this.phone_main = phone_id;
      },
      _ => {}
    );
  }

  phoneCreate() {
    if (this.formGroup.value.phone_new.length < 18) {
      this.onError('Неверный номер телефона');
      return false;
    }
    this.http.post('Phones.add_phone_org',
      {'organisation_id': +this.entityId, 'phone': this.formGroup.value.phone_new}
    ).subscribe(
      (response) => {
        this.onSuccess(response);
        this.phones.push({
          'phone': this.formGroup.value.phone_new,
          'phone_id': response,
          'phone_is_main': null,
        });
        this.formGroup.get('phone_new').setValue('');
      },
      (error) => { }
    );
  }

  onSuccess(response) {
    this.notify.notifySuccess('Поздравляем', 'Данные успешно сохранены', 3000);
  }

  onError(error) {
    this.notify.notifyError('Ошибка', error, 5000);
  }

}
