
  <div class="row mt-4 mb-2">
    <div class="col-2 col-md-3 col-lg-1 pt-4">
      Всего: <b>{{count}}</b>
    </div>
    <div class="form-group col-md-4">
      <div class="row" [formGroup]="form">
        <div class="col-6">
          <app-material-input-datepicker [cssClass]="'mb-0'" formControlName="date_from">Действия с</app-material-input-datepicker>
        </div>
        <div class="col-6">
          <app-material-input-datepicker [cssClass]="'mb-0'" formControlName="date_to">Действия по</app-material-input-datepicker>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="form__group">
        <div class="form__input mt-3">
          <input class="input" type="text" placeholder="Поиск"
                 [(ngModel)]="searchValue">
          <div class="form__input_icon icon icon_Small_Search c-pointer ng-star-inserted"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="material-datatable-wrapper">
    <div class="table-responsive">
      <table class="table">
        <tr>
          <th *ngFor="let el of head" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
            <div [formGroup]="form">
              <div class="row no-gutters align-items-center flex-nowrap" [ngStyle]="{'width': (el.width) ? el.width : 'auto'}">
                <div class="col table__input">
                  <ng-container *ngIf="el.input">
                    <app-material-input
                      [placeholder] = "el.caption"
                      [formControlName] = "el.name"
                      [cssClass]="'mb-0'">
                    </app-material-input>
                  </ng-container>
                  <ng-container *ngIf="el.select">
                    <app-material-select
                      [formControlName]="el.name"
                      [cssClass]="'mb-0'"
                      [placeholder]="el.caption"
                    >
                      <option *ngFor="let opt of el.select" [value]="opt.id">{{opt.caption}}</option>
                    </app-material-select>
                  </ng-container>
                  <ng-container *ngIf="!el.input && !el.select">
                    {{el.caption}}
                  </ng-container>
                </div>
                <div class="col-auto" *ngIf="el.sort">
                  <a href="javascript:void(0)" (click)="sortChange(el.name)">
                    <i class="icon" [ngClass]="{'icon_new-sort-none': true == !sort[el.name],
                                      'icon_new-sort-down': 'asc' == sort[el.name],
                                      'icon_new-sort-up': 'desc' == sort[el.name]}"></i>
                  </a>
                </div>
              </div>
            </div>
          </th>
        </tr>
        <tr *ngFor="let row of rows; let row_index = index">
          <td>
            <span *ngIf="row['datetime']">{{row['datetime']}}</span>
            <span *ngIf="!row['datetime']">Сейчас</span>
          </td>
          <td>
            <span *ngIf="row['action'] === 'publish'">Опубликован на витрину</span>
            <span *ngIf="row['action'] === 'unpublish'">Снят с витрины</span>
          </td>
          <td>
            <a [routerLink]="['/housing2/complex/', row['complex_id']]">{{row['complex_caption']}}</a>
          </td>
          <td>
            <a *ngIf="row['housing']" class="" href="javascript: void(0)"
               (click)="viewDetailInfo(row['complex_caption'], row['housing'], $event)">
              Подробнее
            </a>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <app-modal *ngIf="modalShow" [caption]="modalCaption" (onClosed)="modalClose()">
    <h3>Корпуса:</h3>
    <app-tab-wrapper [tabClass]="'xs-width-33'" [cssClass]="'mobile-tabs-scroll'">
      <app-tab-element *ngFor="let house of modalContent"
        [caption]="house['caption']"
      >
        <a [routerLink]="['/housing2/house/', house['id']]">Ссылка на корпус</a>
        <div class="material-datatable-wrapper">
          <div class="table-responsive">
            <table class="table">
              <tr>
                <th>Дата</th>
                <th>ID квартиры</th>
                <th>Номер</th>
                <th>Действие</th>
              </tr>
              <tr *ngFor="let flat of house['flats']">
                <td>{{flat['datetime']}}</td>
                <td>{{flat['id']}}</td>
                <td>{{flat['caption']}}</td>
                <td>
                  <span *ngIf="flat['action'] === 'publish'">Добавлена</span>
                  <span *ngIf="flat['action'] === 'unpublish'">Убрана</span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </app-tab-element>
    </app-tab-wrapper>
  </app-modal>

<div class="alt-pagination">
  <app-paginator
    [queryParams]="filterColumns"
    [itemsPerPage]="50"
    [itemCount]="count"
    [currentPage]="currentPage"
    [baseUrl]="'/realty-showcase-items/'">
  </app-paginator>
</div>
