import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {AlternativeDatatablesInterface} from '../../shared/alternative-datatables/alternative-datatables.interface';
import {AlternativeDatatablesComponent} from '../../shared/alternative-datatables/alternative-datatables.component';
import {GoBackButtonService} from '../../services/go-back-button.service';
import {JsonClientService} from '../../shared/json-client';
import {NotificationService} from '../../services/notification.service';
import {TemplateService} from '../../services/template.service';

declare const moment;

@Component({
  templateUrl: './realty-showcase-items.component.html',
  styleUrls: [
    './realty-showcase-items.component.scss'
  ]
})
export class RealtyShowcaseItemsComponent
  extends AlternativeDatatablesComponent
  implements AlternativeDatatablesInterface, OnInit, OnDestroy, AfterViewInit {

  public head;

  public filterColumns = {};
  public form: UntypedFormGroup;
  public filter: string;

  private parasites = true;

  public filterSubject: Subject<void> = new Subject<void>();
  private filterSubscription: Subscription;
  private formSubscription: Subscription;

  public currentPage = 1;

  public count;
  public rows;
  public statistics = {};

  public sort: { [key: string]: string } = {};

  public currentFlat: any = null;

  public modalShow = false;
  public modalContent = '';
  public modalCaption = '';

  public set data(value) {
    this.count = value['count'];
    this.rows = value['rows'];
  }

  public set searchValue(value: any) {
    this.filter = value;
    this.onFilterChanges(this.filter);
  }

  public get searchValue(): any {
    return this.filter;
  }


  constructor(public http: JsonClientService,
              private notify: NotificationService,
              public goBack: GoBackButtonService,
              private router: Router,
              private fb: UntypedFormBuilder,
              public activatedRoute: ActivatedRoute,
              private template: TemplateService,
              private cdr: ChangeDetectorRef) {
    super(router, activatedRoute, fb, http);

    this.filterSubscription = this.filterSubject
      .pipe(debounceTime(500))
      .subscribe(_ => this.onFilterChanges());
  }

  bSet() {
    this.goBack.header = '<h2>История публикаций</h2>';
    this.template.setHeader('История публикаций');
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      'date_from': [this.activatedRoute.snapshot.queryParams['date_from'] || moment().format('DD.MM.YYYY')],
      'date_to': [this.activatedRoute.snapshot.queryParams['date_to'] || moment().format('DD.MM.YYYY')],
    });

    this.head = [
      {'name': 'datetime', 'value': '', 'caption': 'Дата изменений', 'input': false, 'sort': true},
      {'name': 'action', 'value': '', 'caption': 'Действие', 'input': false, 'sort': true},
      {'name': 'complex_caption', 'value': '', 'caption': 'Комплекс', 'input': false, 'sort': false},
      {'name': 'modal', 'value': '', 'caption': '', 'input': false, 'sort': false},
    ];

    this.head = this.head.map(x => {
      x.value = this.activatedRoute.snapshot.queryParams[x.name] || x.value;
      return x;
    });
    if (this.activatedRoute.snapshot.queryParams['sort_name']) {
      this.sort[this.activatedRoute.snapshot.queryParams['sort_name']] = this.activatedRoute.snapshot.queryParams['sort_key'];
    }

    this.formSubscription = this.form.valueChanges
      .subscribe(_ => this.filterSubject.next());

    for (const el of this.head) {
      this.form.addControl(el.name, new UntypedFormControl(el.value));
    }

    this.filter = this.activatedRoute.snapshot.queryParams['global_search_string'] || '';
    this.onInitCall();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }

    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  onFilterChanged(): void {
    this.filter = this.activatedRoute.snapshot.queryParams['global_search_string'] || '';
    this.data = this.activatedRoute.snapshot.data['data'];
    this.currentPage = +this.activatedRoute.snapshot.queryParams['page'] || 1;
  }

  onFilterChanges(text = null) {
    if (text !== null) {
      this.filter = text;
    }

    this.filterColumns = {};
    const cols = [...this.head];
    cols.push({
      name: 'date_from',
    }, {
      name: 'date_to',
    });
    for (const el of cols) {
      if (!this.form.get(el.name).value.length) {
        continue;
      }
      this.filterColumns[el.name] = this.form.get(el.name).value;
    }
    if (this.filter !== '') {
      this.filterColumns['global_search_string'] = this.filter;
    }
    if (this.sort) {
      this.filterColumns['sort_name'] = Object.keys(this.sort)[0];
      this.filterColumns['sort_key'] = this.sort[this.filterColumns['sort_name']];
    }
    if (this.parasites) {
      return this.parasites = !this.parasites;
    }
    this.bSet();
    this.router.navigate(['realty-showcase-items'], {
      queryParams: this.getQueryParams(),
    });
  }

  getQueryParams() {
    const query_params = {}, cols = [...this.head];
    cols.push({
      name: 'date_from',
    }, {
      name: 'date_to',
    });
    for (const el of cols) {
      let _val = this.form.get(el.name).value;
      if (!_val.length) {
        continue;
      }
      if (_val === 'true' || _val === 'false') {
        _val = _val === 'true';
      }
      query_params[el.name] = _val;
    }
    if (this.sort) {
      query_params['sort_name'] = Object.keys(this.sort)[0];
      query_params['sort_key'] = this.sort[query_params['sort_name']];
    }
    if (this.filter !== '') {
      query_params['global_search_string'] = this.filter;
    }
    return query_params;
  }

  prepareSort(column_name: string) {
    for (const key in this.sort) {
      if (column_name !== key) {
        delete this.sort[key];
      }
    }
  }

  sortChange(column_name: string) {
    this.prepareSort(column_name);
    this.sort[column_name] = !this.sort[column_name] || ('desc' === this.sort[column_name]) ? 'asc' : 'desc';
    this.onFilterChanges();
  }

  ngAfterViewInit() {
    this.bSet();
    this.goBack.goScroll();
    this.cdr.detectChanges();
  }

  viewDetailInfo(complexCaption: any, houses: any, event) {
    event.stopPropagation();
    this.modalShow = true;
    this.modalCaption = complexCaption;
    this.modalContent = houses;
  }

  modalClose() {
    this.modalShow = false;
    this.modalCaption = '';
    this.modalContent = '';
  }
}
