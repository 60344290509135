<div class="material-datatable-wrapper">
  <app-datatable-headline
    [global_search_string]="global_search_string"
    [items_per_page_vars]="items_per_page_vars"
    (items_per_page_change)="itemPerPageChange($event)"
    (global_search_change)="onGlobalSearch($event)"
    [templateRight]="buttonCreate">
  </app-datatable-headline>
  <div class="table-responsive">
    <table class="table table-striped datatable table-rwd">
      <thead app-data-table-head
             [sort]="sort"
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             (rowClick)="rowClick.emit($event)"
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    [current_page]="current_page"
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>


<ng-template #buttonCreate>
  <div class="text-right">
    <a class="button button_sm" href="javascript: void(0)" (click)="createEntity()">
      Создать<span class="d-none d-md-inline"> юридическое лицо</span>
    </a>
  </div>
</ng-template>


<ng-template #clientTmpl let-row="row" let-ind="row_index">
  <div [ngClass]="{'activeBox': goBack.activeHash === 'organisation_edit-' + ind}">
    <a href="/entity/edit{{row.organisation_id}}"
       (click)="goBack.newLink('/entity/edit/' + row.organisation_id, 'organisation_edit-' + ind, $event)">
      <i class="fa fa-user"></i>
    </a>
  </div>
</ng-template>

<ng-template #idHead let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="organisation_id" [cssClass]="'mb-0'" [placeholder]="'#'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #captionHead let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="organisation_caption" [cssClass]="'mb-0'" [placeholder]="'Название'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #addressHead let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="organisation_address" [cssClass]="'mb-0'" [placeholder]="'Адрес'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #innHead let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="organisation_inn" [cssClass]="'mb-0'" [placeholder]="'ИНН'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #authorHead let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="organisation_author" [cssClass]="'mb-0'" [placeholder]="'Автор'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #organisation_statusHead let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-select formControlName="organisation_status_id" [cssClass]="'mb-0'" [placeholder]="'Статус'">
      <option [value]="''">Все</option>
      <option [value]="'sa:active'">СА - Полный доступ</option>
      <option [value]="'sa:noactive'">СА - Неполный доступ</option>
      <option [value]="'org_type:1'">Юридическое лицо</option>
      <option [value]="'org_type:2'">Банк</option>
      <option [value]="'org_type:3'">Агентство</option>
      <option [value]="'org_type:4'">Застройщик</option>
      <option [value]="'org_type:5'">Подрядчик</option>
      <option [value]="'org_type:6'">Партнер</option>
      <option [value]="'org_type:7'">Частный покупатель</option>
      <option [value]="'org_type:8'">Пресса</option>
      <option [value]="'org_type:9'">Брокер</option>
      <option [value]="'org_type:10'">Самозанятый</option>
    </app-material-select>
  </form>
</ng-template>

<ng-template #organisation_statusTmpl let-row="row">
  {{row.organisation_status}}
  <ng-container *ngIf="row.organisation_overall_unique_is === true">
    <br/><b class="text-nowrap">Общая проверка ЖК</b>
  </ng-container>
</ng-template>

<ng-template #managerHead let-row="row">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="organisation_subagent_manager_id" [cssClass]="'mb-0'" [placeholder]="'Ответственный'">
    </app-material-input>
  </form>
</ng-template>
