import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {JsonClientService} from '../../shared/json-client';

declare const moment;

@Injectable()
export class RealtyShowcaseItemsResolve {

  constructor(public jsonRpc: JsonClientService) {
  }

  private filter_columns =
    [
      'date_from',
      'date_to',
      'datetime',
    ];

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {

    const prepared_filter = {};
    const prepared_filter_keys = this.filter_columns.filter(x =>
      ['date_from', 'date_to'].includes(x) || !!route.parent.queryParams[x]
    );
    const sort = {};

    prepared_filter_keys.forEach(x => {
      let _x = route.parent.queryParams[x];
      if ((x === 'date_to' || x === 'date_from') && _x === undefined) {
        _x = moment().format('DD.MM.YYYY');
      }
      prepared_filter[x] = _x;
    });

    if (!!route.queryParams['sort_name']) {
      sort[route.queryParams['sort_name']] = route.queryParams['sort_key'] || 'asc';
    }
    const options = {
      'limit': 50,
      'offset': (+(route.queryParams['page'] || 1) - 1) * 50,
      'filter': {
        'global_search_string': route.queryParams['global_search_string'] || null,
        'fields': prepared_filter,
      },
      'sort': sort
    };

    return this.jsonRpc.post('ShowcaseItems.get_table_logs', options);
  }
}
