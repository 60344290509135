<div class="tab-content-wrapper mt-4">
  <form class="form form_crm" [formGroup]="formGroup" novalidate>
    <div class="row no-gutters gutters-4">
      <div class="col-6">
        <app-material-select formControlName="organisation_type_id" [require]="false" [caption]="'Тип лица'">
          <option [value]="''">-- Не выбрано --</option>
          <option *ngFor="let type of types" [value]="type.organisation_types_id.toString()">
            {{type.organisation_types_caption}}
          </option>
        </app-material-select>
        <app-material-select formControlName="organisation_owner_id" [require]="false" [caption]="'Ответственный'">
          <option [value]="''">-- Не выбрано --</option>
          <option *ngFor="let owner of owners" [value]="owner.organisation_owner_id.toString()">
            {{owner.organisation_owner_caption}}
          </option>
        </app-material-select>

        <app-material-input formControlName="organisation_socrachcaption" [placeholder]="'Сокращенное наименование организации'">
          Сокращенное наименование организации
        </app-material-input>
        <app-material-input formControlName="organisation_caption" [placeholder]="'Полное наименование организации'">
          Полное наименование организации
        </app-material-input>
        <app-material-input formControlName="organisation_komm_caption" [placeholder]="'Коммерческое название организации'">
          Коммерческое название организации
        </app-material-input>
        <app-material-select formControlName="organisation_form_id" [require]="false" [caption]="'Форма предприятия'">
          <option [value]="''">-- Не выбрано --</option>
          <option *ngFor="let form of forms" [value]="form.organisation_form_id">
            {{form.organisation_form_caption}}
          </option>
        </app-material-select>
        <app-material-input formControlName="organisation_address" [placeholder]="'Юридический адрес'">
          Юридический адрес
        </app-material-input>
        <app-material-input formControlName="organisation_fact_addr" [placeholder]="'Фактический адрес'">
          Фактический адрес
        </app-material-input>
        <app-material-input formControlName="organisation_post_address" [placeholder]="'Почтовый адрес'">
          Почтовый адрес
        </app-material-input>
        <app-material-input formControlName="organisation_gendir_fio" [placeholder]="'ФИО директора'">
          ФИО директора
        </app-material-input>
        <app-material-input formControlName="organisation_dolj_ryk" [placeholder]="'Должность руководителя'">
          Должность руководителя
        </app-material-input>
        <app-material-input formControlName="organisation_osn_dolj" [placeholder]="'Основание должности'">
          Основание должности
        </app-material-input>
        <app-material-input formControlName="organisation_glbuh_fio" [placeholder]="'ФИО гл. бухгалтера'">
          ФИО гл. бухгалтера
        </app-material-input>
      </div>
      <div class="col-6">
        <app-material-input-checkbox
          *ngIf="formGroup.get('organisation_type_id').value === '4'"
          formControlName="organisation_overall_unique_is"
        >
          Общая проверка на уникальность всех ЖК данного застройщика
        </app-material-input-checkbox>
        <app-material-input-checkbox
          formControlName="organisation_not_statistic_is"
        >
          Не отображать в сводном отчете по привлечению
        </app-material-input-checkbox>
        <app-material-input [placeholder]="'Дата создания'" formControlName="organisation_create_date">
          Дата создания
        </app-material-input>
        <app-material-input formControlName="organisation_author_fio" [placeholder]="'Автор'">
          Автор
        </app-material-input>
        <app-material-input formControlName="organisation_email" [placeholder]="'Email'">
          Email
        </app-material-input>
        <app-material-input formControlName="organisation_rekl_email" [placeholder]="'Email для рекламы'">
          Email для рекламы
        </app-material-input>
        <app-material-input-mask formControlName="organisation_rekl_tel" [mask]="'+7 (000) 000-00-00'"
                                 [placeholder]="'Телефон для рекламы'">
          Телефон для рекламы
        </app-material-input-mask>
        <app-material-input formControlName="organisation_website" [placeholder]="'Сайт организации'">
          Сайт организации
        </app-material-input>
        <app-material-textarea formControlName="organisation_notes" [placeholder]="'Заметки'">
          Заметки
        </app-material-textarea>
        <app-material-select2 *ngIf="data['organisation_is_subagent']" formControlName="organisation_manager_id" [require]="false"
                              [caption]="'Персональный менеджер'" [placeholder]="'-- Не выбрано --'">
          <option [value]="''">-- Не выбрано --</option>
          <option *ngFor="let manager of managers" [value]="manager.manager_id">
            {{manager.manager_fio}}
          </option>
        </app-material-select2>
        <app-material-input formControlName="organisation_yandex_id" [placeholder]="'Yandex ID'">
          Yandex ID
        </app-material-input>
        <p class="caption-sm mt-4">Телефоны</p>
        <div class="form__group" *ngFor="let phone of phones">
          <div class="row no-gutters align-items-center">
            <label class="form__checkbox form__checkbox_icon_done_mark">
              <input type="checkbox" (change)="phoneMain(phone['phone_id'])"
                     [disabled]="phone_main === phone['phone_id']"
                     [checked]="phone_main === phone['phone_id']">
              <span class="form__checkbox__wrapper">
                      <span class="form__checkbox__wrapper__toggler icon"></span>
                    </span>
              <span class="form__checkbox__content form__checkbox__content_phone">{{phone['phone']}}</span>
            </label>
            <a class="ml-3 button button_icon button_icon_sub" (click)="phoneDelete(phone)">
              <i class="fa fa-trash-o c-white"></i>
            </a>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-auto">
            <app-material-input-mask [mask]="'+7 (000) 000-00-00'" [placeholder]="'+7 (___) ___-__-__'"
                                     formControlName="phone_new">
            </app-material-input-mask>
          </div>
          <div class="col-auto ml-2 mt-1">
            <button class="button button_sub" (click)="phoneCreate()">Добавить</button>
          </div>
        </div>
      </div>
      <div class="col-12 mt-4">
        <button (click)="saveForm()" type="button" class="button button_main">
          Сохранить
        </button>
      </div>
    </div>
  </form>
</div>
