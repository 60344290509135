import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CrmDashboardComponent } from './_crm/dashboard/crm-dashboard.component';
import { AuthGuard } from './guards/auth.guard';
import { AuthenticateLoginComponent } from './shared/authenticate/authenticate-login.component';
import { SingleWindowComponent } from './_crm/single-window/single-window.component';
import { EntityListComponent } from './_crm/entity/entity-list.component';
import { PhysicalListComponent } from './_crm/physical/physical-list.component';

import { PhysicalEditComponent } from './_crm/physical/physical-edit/physical-edit.component';
import { PhysicalEditCommonComponent } from './_crm/physical/physical-edit/physical-edit-common.component';
import { PhysicalAboutResolve, PhysicalAdditionallyResolve, PhysicalCommonResolve,
  PhysicalDocumentsResolve, PhysicalOrganizationResolve, PhysicalPhotoResolve,
  PhysicalUserResolve } from './_crm/physical/shared/physical.resolve';
import { PhysicalEditAdditionallyComponent } from './_crm/physical/physical-edit/physical-edit-additionally.component';
import { PhysicalEditOrganizationComponent } from './_crm/physical/physical-edit/physical-edit-organization.component';
import { PhysicalEditUserComponent } from './_crm/physical/physical-edit/physical-edit-user.component';
import { PhysicalEditPhotoComponent } from './_crm/physical/physical-edit/physical-edit-photo.component';
import { PhysicalEditDocumentsComponent } from './_crm/physical/physical-edit/physical-edit-documents.component';
import { DictionaryNationalityResolver } from './resolvers/dictionary/dictionary-nationality.resolve';
import { DictionaryUserRolesResolver } from './resolvers/dictionary/dictionary-user-roles.resolve';
import { DictionaryOrganisationPostsResolver } from './resolvers/dictionary/dictionary-organisation-posts.resolve';

import { CalendarComponent } from './shared/calendar/calendar.component';
import { CalendarListComponent } from './shared/calendar/list';
import { CalendarEventComponent } from './shared/calendar/event/calendar-event.component';

import {
  EntityAboutResolve,
  EntityAdditionallyResolve,
  EntityCommonResolve,
  EntityDocumentsResolve,
  EntityPhysicalResolve,
  EntityReglamentResolve,
  PhyToEntityResolve
} from './_crm/entity/shared/entity.resolve';
import { EntityEditCommonComponent } from './_crm/entity/entity-edit/entity-edit-common.component';
import { EntityEditAdditionallyComponent } from './_crm/entity/entity-edit/entity-edit-additionally.component';
import { EntityEditDocumentsComponent } from './_crm/entity/entity-edit/entity-edit-documents.component';
import { EntityEditPhysicalComponent } from './_crm/entity/entity-edit/entity-edit-physical.component';
import { EntityEditComponent } from './_crm/entity/entity-edit/entity-edit.component';
import { DictionaryOrganisationFormsResolver } from './resolvers/dictionary/dictionary-organisation-forms.resolve';
import { DictionaryOrganisationTypesResolver } from './resolvers/dictionary/dictionary-organisation-types.resolve';
import { DictionaryOrganisationTaxFormsResolver } from './resolvers/dictionary/dictionary-organisation-tax-forms.resolve';

import { CalendarInvitesComponent } from './shared/calendar/invites/calendar-invites.component';
import { CalendarListResolve } from './shared/calendar/list';
import { CalendarCreateResolve, CalendarCreateResolveInvites } from './shared/calendar/event/calendar-event.resolve';

import { CalendarListWrapperComponent } from './shared/calendar/list';
import { CalendarEditResolve } from './shared/calendar/event/calendar-edit.resolve';
import { CalendarTeachingComponent } from './shared/calendar/teaching/calendar-teaching.component';
import { CalendarTeachingResolve, CalendarTeachingResolveStatistic } from './shared/calendar/teaching/calendar-teaching.resolve';
import { AllEventsComponent } from './shared/calendar/all-events/all-events.component';
import { AllEventsResolve } from './shared/calendar/all-events/all-events.resolve';

import { OnlineComponent } from './_crm/online/online.component';
import { OnlineResolve } from './_crm/online/online.resolve';
import { OnlineCompareViewWrapperComponent } from './_crm/online/compare-view/online-compare-view-wrapper.component';
import { OnlineCompareViewComponent } from './_crm/online/compare-view/online-compare-view.component';
import { CalendarStatisticComponent } from './shared/calendar/statistic/calendar-statistic.component';
import { SettingsConstraintsComponent } from './shared/settings/constraints/settings-constraints.component';
import { SettingsConstraintsElementComponent } from './shared/settings/constraints/settings-constraints-element.component';
import { CalendarStatisticResole } from './shared/calendar/statistic/calendar-statistic.resole';
import { SettingsConstraintsConsolidatedResolve, SettingsConstraintsElementResolve } from './shared/settings/constraints/settings-constraints-element.resolve';
import { NewsListComponent } from './_crm/news/list/news-list.component';
import { DictionaryManagersResolve } from './resolvers/dictionary/dictionary-managers.resolve';
import { StatisticPluginComponent } from './_crm/statistic/statistic-plugin/statistic-plugin.component';
import { StatisticPluginSiteComponent } from './_crm/statistic/statistic-plugin/statistic-plugin-site.component';
import { PluginResolve } from './_crm/statistic/shared/plugin.resolve';
import { ReserveListComponent } from './_crm/reserve/reserve.component';
import { CalendarStatisticUsersComponent } from './shared/calendar/statistic/calendar-statistic-users.component';
import { CalendarMapComponent } from './shared/calendar/map/calendar-map.component';
import { BookingListComponent } from './_crm/reserve/booking.component';
import { QueueListComponent } from './_crm/reserve/queue.component';
import { ViewsListComponent } from './_crm/reserve/views.component';
import { CalendarCertificateComponent } from './shared/calendar/certificate/certificate.component';
import { PhysicalEditCertificateComponent } from './_crm/physical/physical-edit/physical-edit-certificate.component';
import { ControlComponent } from './_crm/control/control.component';
import { ControlLayoutsComponent } from './_crm/control/control-layouts/control-layouts.component';
import { DictionaryFlatStatusesResolve } from './resolvers/dictionary/dictionary-flat-statuses.resolve';
import { ControlConditionsComponent } from './_crm/control/control-conditions/control-conditions.component';

import { ControlImportComponent } from './_crm/control/control-import/control-import.component';
import { CalendarUserListComponent } from './shared/calendar/user-list/calendar-user-list.component';

import { ControlXmldelComponent } from './_crm/control/control-xmldel/control-xmldel.component';
import { ControlXmlonComponent } from './_crm/control/control-xmlon/control-xmlon.component';
import {ImportXlsxComponent} from './_crm/import-xlsx/import-xlsx.component';

import {
  AttractionNotRegisteredComponent, AttractionStatusCreatedComponent, AttractionStatusPassedComponent,
  AttractionSubagentsComponent
} from './_crm/attraction';
import {SubagentsActionsComponent} from './_crm/subagents-actions/subagents-actions.component';
import {ReferenceComponent} from './_crm/reference/reference.component';
import {AttractionLeadComponent} from './_crm/attraction';
import {DictionaryLeadStageList} from './resolvers/dictionary/dictionary-lead-stage-list';
import {ControlCrmNotImportedFlatsComponent} from './_crm/control/control-crm-not-imported-flats/control-crm-not-imported-flats.component';
import {ControlCrmNotImportedFlatsResolve} from './_crm/control/control-crm-not-imported-flats/control-crm-not-imported-flats.resolve';
import {ControlCrmNotRelatedFlatsComponent} from './_crm/control/control-crm-not-related-flats/control-crm-not-related-flats.component';
import {ControlCrmNotRelatedFlatsResolve} from './_crm/control/control-crm-not-related-flats/control-crm-not-related-flats.resolve';
import {ReservLeadComponent} from './_crm/reserve/reserv-lead/reserv-lead.component';

import {ImportXlsxDictResolve, ImportXlsxResolve} from './_crm/import-xlsx/import-xlsx.resolve';
import {ReservLeadPageCreateComponent} from './_crm/reserve/reserv-lead/reserv-lead-page-create/reserv-lead-page-create.component';
import {ReservLeadPageEditComponent} from './_crm/reserve/reserv-lead/reserv-lead-page-edit/reserv-lead-page-edit.component';
import {ReservLeadPageEditResolve} from './_crm/reserve/reserv-lead/reserv-lead-page-edit/reserv-lead-page-edit.resolve';
import {ManagersComponent} from './_crm/managers/managers.component';
import {ManagersResolve} from './_crm/managers/managers.resolve';
import {ManagersCreateComponent} from './_crm/managers/managers-create/managers-create.component';
import {ManagersEditResolve} from './_crm/managers/managers-edit/managers-edit.resolve';
import {ManagersEditComponent} from './_crm/managers/managers-edit/managers-edit.component';
import {AttractionLeadPageCreateComponent} from './_crm/attraction/attraction-lead/attraction-lead-page-create/attraction-lead-page-create.component';
import {AttractionLeadPageEditResolve} from './_crm/attraction/attraction-lead/attraction-lead-page-edit/attraction-lead-page-edit.resolve';
import {AttractionLeadPageEditComponent} from './_crm/attraction/attraction-lead/attraction-lead-page-edit/attraction-lead-page-edit.component';
import {EntityEditAddressComponent} from './_crm/entity/entity-edit/entity-edit-address.component';

import {ReservLeadInfoComponent} from './_crm/reserve/reserv-lead/reserv-lead-info/reserv-lead-info.component';
import {ReservLeadInfoTodayComponent} from './_crm/reserve/reserv-lead/reserv-lead-info/reserv-lead-info-today/reserv-lead-info-today.component';
import {ReservLeadInfoAllComponent} from './_crm/reserve/reserv-lead/reserv-lead-info/reserv-lead-info-all/reserv-lead-info-all.component';
import {ReservLeadInfoResolve} from './_crm/reserve/reserv-lead/reserv-lead-info/reserv-lead-info.resolve';
import {ReservLeadInfoAllResolve} from './_crm/reserve/reserv-lead/reserv-lead-info/reserv-lead-info-all/reserv-lead-info-all.resolve';
import {ReservLeadInfoTodayResolve} from './_crm/reserve/reserv-lead/reserv-lead-info/reserv-lead-info-today/reserv-lead-info-today.resolve';

import {AttractionLeadInfoComponent} from './_crm/attraction/attraction-lead/attraction-lead-info/attraction-lead-info.component';
import {AttractionLeadInfoTodayComponent} from './_crm/attraction/attraction-lead/attraction-lead-info/attraction-lead-info-today/attraction-lead-info-today.component';
import {AttractionLeadInfoAllComponent} from './_crm/attraction/attraction-lead/attraction-lead-info/attraction-lead-info-all/attraction-lead-info-all.component';
import {AttractionLeadInfoResolve} from './_crm/attraction/attraction-lead/attraction-lead-info/attraction-lead-info.resolve';
import {AttractionLeadInfoAllResolve} from './_crm/attraction/attraction-lead/attraction-lead-info/attraction-lead-info-all/attraction-lead-info-all.resolve';
import {AttractionLeadInfoTodayResolve} from './_crm/attraction/attraction-lead/attraction-lead-info/attraction-lead-info-today/attraction-lead-info-today.resolve';
import {ViewListResolve} from './_crm/reserve/main-resolvers/views.resolve';
import {ReserveListResolve} from './_crm/reserve/main-resolvers/reserve.resolve';
import {BookingResolve} from './_crm/reserve/main-resolvers/booking.resolve';
import {BookingInfoComponent} from './_crm/reserve/booking-info/booking-info.component';
import {BookingInfoResolve} from './_crm/reserve/booking-info/booking-info.resolve';
import {
  BookingInfoContentDataResolve,
  BookingInfoContentResolve
} from './_crm/reserve/booking-info/booking-info-content/booking-info-content.resolve';
import {BookingInfoContentComponent} from './_crm/reserve/booking-info/booking-info-content/booking-info-content.component';
import {DictionaryLeadSourcesResolve} from './resolvers/dictionary/dictionary-lead-sources.resolve';
import {EntityEditAddressResolve} from './_crm/entity/entity-edit/entity-edit-address.resolve';
import {UniqueListComponent} from './_crm/reserve/unique.component';
import {UniqueListResolve} from './_crm/reserve/main-resolvers/unique.resolve';
import {NotFoundCrmComponent} from './errors/404-not-found-crm/not-found-crm.component';
import {HousingOrderComponent} from './_crm/housing-order/housing-order.component';
import {HousingOrderResolve} from './_crm/housing-order/housing-order.resolve';
import {CrmDashboardResolve} from './_crm/dashboard/crm-dashboard.resolve';
import {TariffMapTableComponent} from './_crm/tariff-map/tariff-map-table.component';
import {EntityEditCertificateComponent} from './_crm/entity/entity-edit/entity-edit-certificate.component';
import {EstatetComponent} from './_crm/estatet/estatet.component';
import {EstatetResolve} from './_crm/estatet/estatet.resolve';
import {DictionaryDevelopmentsResolver} from './resolvers/dictionary/dictionary-developments.resolve';
import {TariffMapLogResolve} from './_crm/tariff-map/tariff-map-log/tariff-map-log.resolve';
import {TariffMapLogComponent} from './_crm/tariff-map/tariff-map-log/tariff-map-log.component';
import {CianYandexTableComponent} from './_crm/cian-yandex/cian-yandex-table.component';
import {CianYandexTableResolve} from './_crm/cian-yandex/cian-yandex-table.resolve';
import {RealtyShowLogsResolve} from './_crm/realty-show-logs/realty-show-logs.resolve';
import {RealtyShowLogsComponent} from './_crm/realty-show-logs/realty-show-logs.component';
import {DictionaryModuleSourceResolver} from './resolvers/dictionary/dictionary-module-source.resolve';
import {EmailExceptionComponent} from './_crm/email-exсeption/email-exсeption.component';
import {TransferEntityComponent} from './_crm/transfer/transfer-entity.component';
import {
  PhyToOrganisationNotCurrentPhyResolve,
  PhyToOrganisationResolve,
} from './_crm/transfer/transfer.resolve';
import {TransferPhysicalComponent} from './_crm/transfer/transfer-physical.component';
import {TransferLogComponent} from './_crm/transfer-log/transfer-log.component';
import {TransferLogResolve} from './_crm/transfer-log/transfer-log.resolve';
import {ComplaintsDatatableComponent} from './shared/complaints-datatable/complaints-datatable.component';
import {DefectiveGuard} from './guards/defective.guard';
import {
  CauseDatatableResolve, ComplaintsCrmPartTrue,
  ComplaintsDatatableResolve
} from './shared/complaints-datatable/complaints-datatable.resolve';
import {DictionaryLeadSubagentAreaWorkResolve} from './resolvers/dictionary/dictionary-lead-subagent-area-work.resolve';
import {InstatekaComponent} from './_crm/instateka/users/instateka.component';
import {InstatekaResolve} from './_crm/instateka/users/instateka.resolve';
import {EatComplexAssocComponent} from './_crm/eat-complex-assoc/eat-complex-assoc.component';
import {EatComplexAssocResolve} from './_crm/eat-complex-assoc/eat-complex-assoc.resolve';
import {InstatekaWrapperComponent} from './_crm/instateka/wrapper/wrapper.component';
import {InstatekaRequestsComponent} from './_crm/instateka/requests/requests.component';
import {InstatekaRequestsResolve, InstatekaRequestsStatisticsResolve} from './_crm/instateka/requests/requests.resolve';
import {ControlIndexComponent} from './_crm/control/control-index/control-index.component';
import {ReglTableComponent} from './_crm/mass-filling/regl-table/regl-table.component';
import {ReglTableResolve} from './_crm/mass-filling/regl-table/regl-table.resolve';
import {MassHelpComponent} from './_crm/mass-filling/regl-help/regl-help.component';
import {MassWrapTabsComponent} from './_crm/mass-filling/wrap-tabs/wrap-tabs.component';
import {EmailExceptionResolve} from './_crm/email-exсeption/email-exception.resolve';
import {HousingControlComponent} from './_crm/housing-control/housing.component';
import {HousingControlResolve} from './_crm/housing-control/housing.resolve';
import {InstatekaErrorComponent} from './_crm/instateka/errors/errors.component';
import {InstatekaErrorResolve} from './_crm/instateka/errors/errors.resolve';
import { HolidayListComponent } from './_crm/holiday/holiday-list.component';
import { StartSalesComponent } from './_crm/start-sales/start-sales.component';
import {BlankComponent} from './_front/blank/blank.component';
import {SettingsIndexComponent} from './shared/settings/index/settings-index.component';
import {SettingsRolesComponent} from './shared/settings/roles/settings-roles.component';
import {SettingsRolesResolve} from './shared/settings/roles/settings-roles.resolve';
import {ContactsUnregResolve} from './_crm/contacts-unreg/contacts-unreg.resolve';
import {ContactsUnregComponent} from './_crm/contacts-unreg/contacts-unreg.component';
import {NewsWrapperComponent} from './_crm/news/wrapper/wrapper.component';
import { NewsActionComponent } from './_crm/news/actions/action.component';
import {PurchTermTableComponent} from './_crm/mass-filling/purch-term-table/purch-term-table.component';
import {PurchTermTableResolve} from './_crm/mass-filling/purch-term-table/purch-term-table.resolve';
import {EstCardLOgComponent} from './_crm/est-card/est-card-log.component';
import {EstCardLOgResolve} from './_crm/est-card/est-card-log.resolve';
import {CeleryErrLogsComponent} from './_crm/celery-err-logs/celery-err-logs.component';
import {CeleryErrLogsResolve} from './_crm/celery-err-logs/celery-err-logs.resolve';
import {RealtyShowcaseItemsComponent} from './_crm/realty-showcase-items/realty-showcase-items.component';
import {RealtyShowcaseItemsResolve} from './_crm/realty-showcase-items/realty-showcase-items.resolve';

const routes: Routes = [
  {
    path: 'housing', loadChildren: () => import('app/shared/housing/housing.module').then(m => m.HousingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'housing2', loadChildren: () => import('app/shared/housing-2/housing-2.module').then(m => m.Housing2Module),
    canActivate: [AuthGuard]
  },
  {path: 'housing-3', loadChildren: () => import('app/shared/housing-3/housing-3.module').then(m => m.Housing3Module),
    canActivate: [AuthGuard]},
  //
  {
    path: 'processes', loadChildren: () => import('app/_crm/processes/processes.module').then(m => m.ProcessesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'organisation-search', loadChildren: () => import('app/_crm/organisation-search/organisation-search.module')
      .then(m => m.OrganisationSearchModule),
    canActivate: [AuthGuard]
  },
  /* Settings */
  {
    path: 'settings', component: SettingsIndexComponent,
    canActivate: [AuthGuard], pathMatch: 'prefix'
  },
  {
    path: 'settings/roles', component: SettingsRolesComponent,
    canActivate: [AuthGuard], pathMatch: 'prefix', resolve: {'data': SettingsRolesResolve}
  },
  {
    path: 'settings/constraints/edit/:subagent_id', component: SettingsConstraintsElementComponent,
    canActivate: [AuthGuard], pathMatch: 'prefix', resolve: {'data': SettingsConstraintsElementResolve}
  },
  {
    path: 'settings/constraints-consolidate', component: SettingsConstraintsElementComponent,
    canActivate: [AuthGuard], pathMatch: 'prefix', resolve: {'data': SettingsConstraintsConsolidatedResolve}
  },
  {
    path: 'settings/constraints',
    component: SettingsConstraintsComponent,
    canActivate: [AuthGuard],
    pathMatch: 'prefix'
  },
  /* Calendar */
  {
    path: 'calendar',
    component: CalendarComponent,
    pathMatch: 'prefix',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'list', component: CalendarListWrapperComponent, pathMatch: 'prefix',
        runGuardsAndResolvers: 'always', children: [
        {
          path: '', component: CalendarListComponent, pathMatch: 'prefix', resolve: {
          'list': CalendarListResolve
        }
        },
        {
          path: ':year/:month', component: CalendarListComponent, pathMatch: 'prefix', resolve: {
          'list': CalendarListResolve
        }
        },
      ]
      },
      {
        path: 'teaching', component: CalendarTeachingComponent, pathMatch: 'prefix', resolve: {
        'statistic': CalendarTeachingResolve,
        'statistic2': CalendarTeachingResolveStatistic,
      }
      },
      {
        path: 'all-events', component: AllEventsComponent, pathMatch: 'prefix', resolve: {
        'statistic': AllEventsResolve,
      }
      },
      {
        path: 'statistic', component: CalendarStatisticComponent, pathMatch: 'prefix',
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {
          'types': CalendarStatisticResole,
        }
      },
      {
        path: 'statistic/:from/:to/:type', component: CalendarStatisticComponent, pathMatch: 'prefix',
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {
          'types': CalendarStatisticResole,
        }
      },
      {
        path: 'users/:id', component: CalendarStatisticUsersComponent, pathMatch: 'prefix', resolve: {
          'data': CalendarEditResolve,
        }
      },
      {
        path: 'create', component: CalendarEventComponent, pathMatch: 'prefix', resolve: {
        'types': CalendarCreateResolve,
        'invites': CalendarCreateResolveInvites,
      }
      },
      {
        path: 'edit/:id', component: CalendarEventComponent, pathMatch: 'prefix', resolve: {
        'types': CalendarCreateResolve,
        'invites': CalendarCreateResolveInvites,
        'data': CalendarEditResolve,
      }
      },
      {path: 'invites', component: CalendarInvitesComponent, pathMatch: 'prefix'},
      {path: 'map', component: CalendarMapComponent, pathMatch: 'prefix'},
      {path: 'certificate', component: CalendarCertificateComponent, pathMatch: 'prefix'},
      {path: 'user/list', component: CalendarUserListComponent, pathMatch: 'prefix'},
      {path: '', redirectTo: 'list', pathMatch: 'prefix'},
    ]
  },
  /* Estatet integration */
  {path: 'estatet', component: EstatetComponent, pathMatch: 'prefix', canActivate: [AuthGuard],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    resolve: {
      'data': EstatetResolve,
    },
  },
  /* Physical */

  {
    path: 'physical',
    pathMatch: 'prefix',
    children: [
      {
        path: 'edit/:id',
        component: PhysicalEditComponent,
        pathMatch: 'prefix',
        resolve: {backref: 'backref_physical', about: PhysicalAboutResolve},
        children: [
          {
            path: 'common', component: PhysicalEditCommonComponent, pathMatch: 'prefix',
            resolve: {data: PhysicalCommonResolve}
          },
          {
            path: 'additionally', component: PhysicalEditAdditionallyComponent, pathMatch: 'prefix',
            resolve: {data: PhysicalAdditionallyResolve, nationality: DictionaryNationalityResolver}
          },
          {
            path: 'organization', component: PhysicalEditOrganizationComponent, pathMatch: 'prefix',
            resolve: {
              data: PhysicalOrganizationResolve,
              posts: DictionaryOrganisationPostsResolver
            }
          },
          {
            path: 'user', component: PhysicalEditUserComponent, pathMatch: 'prefix',
            resolve: {data: PhysicalUserResolve, roles: DictionaryUserRolesResolver}
          },
          {
            path: 'photo', component: PhysicalEditPhotoComponent, pathMatch: 'prefix',
            resolve: {data: PhysicalPhotoResolve}
          },
          {
            path: 'documents', component: PhysicalEditDocumentsComponent, pathMatch: 'prefix',
            resolve: {data: PhysicalDocumentsResolve}
          },
          {
            path: 'certificate', component: PhysicalEditCertificateComponent, pathMatch: 'prefix'
          },
          {path: '', redirectTo: 'common', pathMatch: 'prefix'},
        ]
      },
      {path: 'list', component: PhysicalListComponent, pathMatch: 'prefix'},
      {path: '', redirectTo: 'list', pathMatch: 'prefix'}
    ]
  },
  /* Entity */
  {
    path: 'entity',
    pathMatch: 'prefix',
    children: [
      {
        path: 'edit/:id',
        component: EntityEditComponent,
        pathMatch: 'prefix',
        resolve: {backref: 'backref_entity', about: EntityAboutResolve},
        children: [
          {
            path: 'common', component: EntityEditCommonComponent, pathMatch: 'prefix',
            resolve: {
              data: EntityCommonResolve,
              types: DictionaryOrganisationTypesResolver,
              forms: DictionaryOrganisationFormsResolver,
              owners: PhyToEntityResolve,
              managers: DictionaryManagersResolve
            }
          },
          {
            path: 'additionally', component: EntityEditAdditionallyComponent, pathMatch: 'prefix',
            resolve: {
              data: EntityAdditionallyResolve,
              nalogForms: DictionaryOrganisationTaxFormsResolver
            }
          },
          {
            path: 'physical', component: EntityEditPhysicalComponent, pathMatch: 'prefix',
            resolve: {
              data: EntityPhysicalResolve,
              posts: DictionaryOrganisationPostsResolver
            }
          },
          {
            path: 'documents', component: EntityEditDocumentsComponent, pathMatch: 'prefix',
            resolve: {
              data: EntityDocumentsResolve,
              reglament: EntityReglamentResolve
            }
          },
          {
            path: 'address', component: EntityEditAddressComponent, pathMatch: 'prefix',
            resolve: {
              data: EntityEditAddressResolve,
            }
          },
          {
            path: 'certificate', component: EntityEditCertificateComponent, pathMatch: 'prefix'
          },
          {path: '', redirectTo: 'common', pathMatch: 'prefix'},
        ]

      },
      {
        path: 'list', component: EntityListComponent, pathMatch: 'prefix',
        resolve: {
          forms: DictionaryOrganisationFormsResolver,
          types: DictionaryOrganisationTypesResolver
        }
      },
      {path: '', redirectTo: 'list', pathMatch: 'prefix'}
    ]
  },
  /* --- */
  /* Online */
  {
    path: 'online', component: OnlineComponent, pathMatch: 'prefix', canActivate: [AuthGuard], resolve: {
    data: OnlineResolve,
  }
  },
  {
    path: 'online/compare', component: OnlineCompareViewWrapperComponent, pathMatch: 'prefix', canActivate: [AuthGuard],
    children: [
      {path: '', component: OnlineCompareViewComponent, pathMatch: 'prefix'}
    ]
  },
  {
    path: 'online/:date', component: OnlineComponent, pathMatch: 'prefix', canActivate: [AuthGuard], resolve: {
    data: OnlineResolve,
  }
  },
  {
    path: 'online/:date/:modification',
    component: OnlineComponent,
    pathMatch: 'prefix',
    canActivate: [AuthGuard],
    resolve: {
      data: OnlineResolve,
    }
  },
  /* News */
  {path: 'news', component: NewsWrapperComponent, canActivate: [AuthGuard], pathMatch: 'prefix', children: [
    {
      path: 'list', component: NewsListComponent, canActivate: [AuthGuard],
      runGuardsAndResolvers: 'always',
    }, {
      path: 'actions', component: NewsActionComponent, canActivate: [AuthGuard],
      runGuardsAndResolvers: 'always',
    }
    ]
  },
  /* Holidays */
  {path: 'holiday/list', component: HolidayListComponent, pathMatch: 'prefix', canActivate: [AuthGuard]},
  /* Statistics */
  {path: 'statistic/plugin', component: StatisticPluginComponent, pathMatch: 'full', canActivate: [AuthGuard]},
  {
    path: 'statistic/plugin/:id', component: StatisticPluginSiteComponent, pathMatch: 'prefix', canActivate: [AuthGuard],
    resolve: {
      data: PluginResolve
    }
  },
  /* Reserves */
  {path: 'reservation/reserve', component: ReserveListComponent, pathMatch: 'prefix', canActivate: [AuthGuard],
    resolve: {
      'data': ReserveListResolve,
    },
  },
  {path: 'reservation/unique', component: UniqueListComponent, pathMatch: 'prefix', canActivate: [AuthGuard],
    resolve: {
      'data': UniqueListResolve,
    },
  },
  {path: 'reservation/booking', component: BookingListComponent, pathMatch: 'prefix', canActivate: [AuthGuard],
    resolve: {
      'data': BookingResolve,
    },
  },

  {path: 'reservation/booking-info', component: BookingInfoComponent, pathMatch: 'prefix', canActivate: [AuthGuard],
    resolve: {
      'data': BookingInfoResolve,
    },
    children: [
      {
        path: ':tab',
        component: BookingInfoContentComponent,
        pathMatch: 'prefix',
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {
          filterdata: BookingInfoContentResolve,
          data: BookingInfoContentDataResolve,
        },
      },
      {path: '', redirectTo: 'all', pathMatch: 'prefix'},
    ]
  },
  {path: 'reservation/views', component: ViewsListComponent, pathMatch: 'prefix', canActivate: [AuthGuard],
    resolve: {
      'data': ViewListResolve,
    },
  },
  {path: 'reservation/queue', component: QueueListComponent, pathMatch: 'prefix', canActivate: [AuthGuard]},
  {path: 'reservation/lead', component: ReservLeadComponent, canActivate: [AuthGuard], pathMatch: 'prefix'},
  {path: 'reservation/lead-info', component: ReservLeadInfoComponent, canActivate: [AuthGuard], pathMatch: 'prefix',
    resolve: {
      'data': ReservLeadInfoResolve,
    },
    children: [
      {
        path: 'today', component: ReservLeadInfoTodayComponent, pathMatch: 'prefix',
        resolve: {
          'data': ReservLeadInfoTodayResolve,
        },
      },
      {
        path: 'all', component: ReservLeadInfoAllComponent, pathMatch: 'prefix',
        resolve: {
          'data': ReservLeadInfoAllResolve,
        },
      },
      {path: '', redirectTo: 'today', pathMatch: 'prefix'}
    ]
  },
  {path: 'reservation/lead-info/create', component: ReservLeadPageCreateComponent, canActivate: [AuthGuard], pathMatch: 'prefix',
    resolve: {
      'leadSources': DictionaryLeadSourcesResolve,
    },
  },
  {path: 'reservation/lead-info/:id', component: ReservLeadPageEditComponent, canActivate: [AuthGuard], pathMatch: 'prefix',
    resolve: {
      'data': ReservLeadPageEditResolve,
      'leadSources': DictionaryLeadSourcesResolve,
    },
  },

  /* Managers */
  {path: 'managers', component: ManagersComponent, canActivate: [AuthGuard], pathMatch: 'prefix',
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    resolve: {
      'data': ManagersResolve,
    },
  },
  {path: 'managers/edit/:id', component: ManagersEditComponent, canActivate: [AuthGuard], pathMatch: 'prefix',
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    resolve: {
      'data': ManagersEditResolve,
    },
  },
  {path: 'managers/create', component: ManagersCreateComponent, canActivate: [AuthGuard], pathMatch: 'prefix'},

  /* Import */
  {path: 'import', loadChildren: () => import('app/shared/import/import.module').then(m => m.ImportModule)},
  {path: 'import_xlsx', component: ImportXlsxComponent, canActivate: [AuthGuard], pathMatch: 'prefix',
    resolve: {
      'information': ImportXlsxResolve,
      'dict': ImportXlsxDictResolve,
    },
  },
  /* Control */
  {path: 'control', component: ControlComponent, canActivate: [AuthGuard], pathMatch: 'prefix', children: [
      {path: 'layouts', component: ControlLayoutsComponent, pathMatch: 'prefix', resolve: {
        'flat_statuses': DictionaryFlatStatusesResolve,
        'developers': DictionaryDevelopmentsResolver
        }
      },
      {path: 'conditions', component: ControlConditionsComponent, pathMatch: 'prefix', resolve: {
        'flat_statuses': DictionaryFlatStatusesResolve
        }
      },
      {path: 'import', component: ControlImportComponent, pathMatch: 'prefix', resolve: {
        'flat_statuses': DictionaryFlatStatusesResolve
        }
      },
      {path: 'xml-del', component: ControlXmldelComponent, pathMatch: 'prefix', resolve: {
        'flat_statuses': DictionaryFlatStatusesResolve
        }
      },
      {path: 'xml-on', component: ControlXmlonComponent, pathMatch: 'prefix', resolve: {
        'flat_statuses': DictionaryFlatStatusesResolve
        }
      },
      {path: 'not-imported', component: ControlCrmNotImportedFlatsComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {
          'data': ControlCrmNotImportedFlatsResolve,
        },
      },
      {path: 'not-related', component: ControlCrmNotRelatedFlatsComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {
          'data': ControlCrmNotRelatedFlatsResolve,
        },
      },
      {path: 'index', component: ControlIndexComponent, pathMatch: 'prefix'},
      {path: '', redirectTo: 'index', pathMatch: 'prefix'}
    ]
  },
  /* Attraction */
  {path: 'attraction/not-registered', component: AttractionNotRegisteredComponent, canActivate: [AuthGuard], pathMatch: 'prefix'},
  {path: 'attraction/subagents', component: AttractionSubagentsComponent, canActivate: [AuthGuard], pathMatch: 'prefix'},
  {path: 'attraction/status-created', component: AttractionStatusCreatedComponent, canActivate: [AuthGuard], pathMatch: 'prefix'},
  {path: 'attraction/status-passed', component: AttractionStatusPassedComponent, canActivate: [AuthGuard], pathMatch: 'prefix'},
  {path: 'attraction/lead', component: AttractionLeadComponent, canActivate: [AuthGuard], pathMatch: 'prefix'},
  {path: 'attraction/lead-info', component: AttractionLeadInfoComponent, canActivate: [AuthGuard], pathMatch: 'prefix',
    resolve: {
      'data': AttractionLeadInfoResolve,
    },
    children: [
      {
        path: 'today', component: AttractionLeadInfoTodayComponent, pathMatch: 'prefix',
        resolve: {
          'data': AttractionLeadInfoTodayResolve,
        },
      },
      {
        path: 'all', component: AttractionLeadInfoAllComponent, pathMatch: 'prefix',
        resolve: {
          'data': AttractionLeadInfoAllResolve,
        },
      },
      {path: '', redirectTo: 'today', pathMatch: 'prefix'}
    ]
  },
  {path: 'attraction/lead-info/create', component: AttractionLeadPageCreateComponent, canActivate: [AuthGuard], pathMatch: 'prefix',
    resolve: {
      'forms': DictionaryLeadStageList,
      'leadSources': DictionaryLeadSourcesResolve,
      'posts': DictionaryOrganisationPostsResolver,
      'nalogForms': DictionaryOrganisationTaxFormsResolver,
      'subagentAreaWork': DictionaryLeadSubagentAreaWorkResolve
    }
  },
  {path: 'attraction/lead-info/:id', component: AttractionLeadPageEditComponent, canActivate: [AuthGuard], pathMatch: 'prefix',
    resolve: {
      'data': AttractionLeadPageEditResolve,
      'leadSources': DictionaryLeadSourcesResolve,
      'subagentAreaWork': DictionaryLeadSubagentAreaWorkResolve
    },
  },
  {path: 'housing-order', component: HousingOrderComponent, canActivate: [AuthGuard], pathMatch: 'prefix',
    resolve: {
      'data': HousingOrderResolve,
    }
  },
  /* Subagents-actions */
  {path: 'subagents-actions', component: SubagentsActionsComponent, canActivate: [AuthGuard], pathMatch: 'prefix'},

  /* Reference */
  {path: 'reference', component: ReferenceComponent, canActivate: [AuthGuard], pathMatch: 'prefix'},
  {path: 'tariff-map', component: TariffMapTableComponent, canActivate: [AuthGuard], pathMatch: 'prefix'},
  {path: 'cian-yandex', component: CianYandexTableComponent, canActivate: [AuthGuard], pathMatch: 'prefix',
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    resolve: {
      'data': CianYandexTableResolve,
    },
  },
  {path: 'email-exception', component: EmailExceptionComponent, canActivate: [AuthGuard], pathMatch: 'prefix',
    resolve: {
      'data': EmailExceptionResolve,
    }},
  {path: 'mass', component: MassWrapTabsComponent, canActivate: [AuthGuard], pathMatch: 'prefix',
    children: [
      {
        path: 'regl_table', component: ReglTableComponent, canActivate: [AuthGuard], pathMatch: 'prefix',
        runGuardsAndResolvers: 'always',
        resolve: {
          'data': ReglTableResolve,
        },
      },
      {
        path: 'purch_temp', component: PurchTermTableComponent, canActivate: [AuthGuard], pathMatch: 'prefix',
        runGuardsAndResolvers: 'always',
        resolve: {
          'data': PurchTermTableResolve,
        },
      }, {
        path: 'help', component: MassHelpComponent, canActivate: [AuthGuard], pathMatch: 'prefix',
      }, {
        path: '', redirectTo: 'regl_table', pathMatch: 'prefix'
      }
    ]
  },
  {path: 'housing-control', component: HousingControlComponent, canActivate: [AuthGuard], pathMatch: 'prefix',
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    resolve: {
      'data': HousingControlResolve,
    },
  },
  {path: 'tariff-map/logs/:housing_id', component: TariffMapLogComponent, canActivate: [AuthGuard], pathMatch: 'prefix',
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    resolve: {
      'data': TariffMapLogResolve,
    },
  },
  {path: 'realty-show-logs', component: RealtyShowLogsComponent, canActivate: [AuthGuard], pathMatch: 'prefix',
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    resolve: {
      'module_source': DictionaryModuleSourceResolver,
      'data': RealtyShowLogsResolve,
    },
  },
  // {path: 'crm', component: CrmDashboardComponent, canActivate: [AuthGuard],
  //   resolve: {
  //     CrmDashboardResolve,
  //   }
  // },
  // Transfer user subagent
  {
    path: 'transfer',
    canActivate: [AuthGuard],
    pathMatch: 'prefix',
    children: [{
      path: 'entity/:id',
      component: TransferEntityComponent,
      resolve: {
        'physicals': PhyToOrganisationResolve,
      }
    },
      {
        path: 'physical/:id',
        component: TransferPhysicalComponent,
        resolve: {
          'physicals': PhyToOrganisationNotCurrentPhyResolve
        }
      }
    ]
    },
  {
    path: 'contacts-unreg', canActivate: [AuthGuard], pathMatch: 'prefix', component: ContactsUnregComponent,
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    resolve: {
      'data': ContactsUnregResolve,
    }
  },
  {
    path: 'transfer-log', canActivate: [AuthGuard], pathMatch: 'prefix', component: TransferLogComponent,
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    resolve: {
      'data': TransferLogResolve,
    }
  },
  // Complaints
    {
    path: 'complaints-datatable', component: ComplaintsDatatableComponent, canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    resolve: {
      'data': ComplaintsDatatableResolve,
      'cause': CauseDatatableResolve,
      'is_crm_part': ComplaintsCrmPartTrue
    }
  },
  {
    path: 'instateka',
    component: InstatekaWrapperComponent,
    canActivate: [AuthGuard],
    pathMatch: 'prefix',
    children: [
      {
        path: 'users', component: InstatekaComponent, canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
        resolve: {data: InstatekaResolve}
      },
      {
        path: 'errors', component: InstatekaErrorComponent, canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
        resolve: {
          'data': InstatekaErrorResolve,
        },
      },
      {
        path: 'requests', component: InstatekaRequestsComponent, canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
        resolve: {
          data: InstatekaRequestsResolve,
          statistics: InstatekaRequestsStatisticsResolve
        }
      },
      {path: '', redirectTo: 'requests', pathMatch: 'prefix'}
    ]
  },
  {
    path: 'eat-complex-captions', component: EatComplexAssocComponent, canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    resolve: {data: EatComplexAssocResolve}
  },
  {path: 'start-sales', component: StartSalesComponent, canActivate: [AuthGuard], pathMatch: 'prefix'},
  {path: 'est-card-log', component: EstCardLOgComponent, pathMatch: 'prefix', canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    resolve: {
      'data': EstCardLOgResolve,
    }
  },
  {path: 'celery-err-log', component: CeleryErrLogsComponent, pathMatch: 'prefix', canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    resolve: {
      'data': CeleryErrLogsResolve,
    }
  },
  {path: 'realty-showcase-items', component: RealtyShowcaseItemsComponent, canActivate: [AuthGuard], pathMatch: 'prefix',
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    resolve: {
      'data': RealtyShowcaseItemsResolve,
    },
  },
  {path: 'blank', component: BlankComponent, canActivate: [AuthGuard], pathMatch: 'prefix'},
  {path: 'authenticate', component: AuthenticateLoginComponent},
  {path: '', redirectTo: 'control/index', pathMatch: 'full'},
  {path: 'crm', redirectTo: 'control/index', pathMatch: 'full'},
  {path: '404', component: NotFoundCrmComponent},
  {path: '**', redirectTo: '404'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule,
  ]
})
export class AppCrmRoutingModule {
}
