import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {PaginatorModule} from '../../shared/housing-modules/modules';
import {MaterialInputModule} from '../../modules/@material-input';
import {DictionaryModuleSourceResolver} from '../../resolvers/dictionary/dictionary-module-source.resolve';
import {RealtyShowcaseItemsComponent} from './realty-showcase-items.component';
import {RealtyShowcaseItemsResolve} from './realty-showcase-items.resolve';
import {ModalModule} from '../../modules/@modal';
import {PopoverModule} from '../../modules/@popover/popober.module';
import {TabsModule} from '../../modules/@tabs/tabs.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialInputModule,
    FormsModule,
    PaginatorModule,
    ReactiveFormsModule,
    ModalModule,
    PopoverModule,
    TabsModule,
  ],
  declarations: [
    RealtyShowcaseItemsComponent,
  ],
  providers: [
    RealtyShowcaseItemsResolve,
    DictionaryModuleSourceResolver,
  ]
})
export class RealtyShowcaseItemsModule { }
