<div class="form__group" [ngClass]="cssClass">
  <div class="form__label" [class.form__label_require]="require && !disabled" [class.form__label_error]="error">{{caption}}</div>
  <ng-container *ngIf="!disabled && (!triggerClick || !checked_option)">
    <div class="form__input form__input_has-icon"
         [class.form__input_disabled]="disabled" tabindex="0"
         [class.form__input_error]="error"
         [class.form__input_focus]="focusing"
         [class.bg-transparent]="tblHeadStyle"
         (focus)="focusing = true"
         (blur)="focusing = false"
         [appDropdown]="'form__group__dropdown_opened'" [globalHide]="true"
         [targetElement]="dropdownWrapper">
      <div class="input__placeholder input__placeholder_pointer"
           [class.tbl-head-padding]="tblHeadStyle"
      >
        <div class="form__input_icon icon icon_Small_Dropdown"></div>
        <span
          [class.tbl-head-caption]="tblHeadStyle"
          *ngIf="checked_option"
          [class.clr-selected]="current_value"
        >
          <ng-container *ngIf="placeholder && !current_value">{{placeholder}}</ng-container>
          <ng-container *ngIf="!placeholder || current_value">{{checked_option.caption}}</ng-container>
        </span>
      </div>
    </div>
    <ul [attr.data-caption]="caption" class="form__group__dropdown form__group__dropdown_ul form__group__dropdown_list"
        #dropdownWrapper>
      <li class="caption d-block-sm-fix d-md-none" (click)="onClose()">
        <i class="form__input_icon icon icon_Small_Dropdown_Back mr-2"></i><span>{{caption}}</span>
      </li>
      <li *ngFor="let option of options" [ngClass]="{'selected': option.checked}"
          (click)="onSelectChange(option)">
        <span>{{option.caption}}</span>
      </li>
    </ul>
  </ng-container>
  <ng-container *ngIf="!disabled && (triggerClick && checked_option)">
    <div class="form__input form__input_has-icon"
         [class.form__input_disabled]="disabled" tabindex="0"
         [class.form__input_error]="error"
         [class.form__input_focus]="focusing">
      <div class="input__placeholder input__placeholder_pointer">
        <div class="form__input_icon icon icon_Small_Dropdown"
             (focus)="focusing = true"
             (blur)="focusing = false"
             [appDropdown]="'form__group__dropdown_opened'" [globalHide]="true"
             [targetElement]="dropdownWrapper"></div>
        <span class="span_hover" *ngIf="checked_option" (click)="onCallBack(checked_option.value)">{{checked_option.caption}}</span>
      </div>
    </div>
    <ul [attr.data-caption]="caption" class="form__group__dropdown form__group__dropdown_ul form__group__dropdown_list" #dropdownWrapper>
      <li class="caption d-block-sm-fix d-md-none" (click)="onClose()">
        <i class="form__input_icon icon icon_Small_Dropdown_Back mr-2"></i><span>{{caption}}</span>
      </li>
      <li *ngFor="let option of options" [ngClass]="{'selected': option.checked}"
          (click)="onSelectChange(option)">
        <span>{{option.caption}}</span>
      </li>
    </ul>
  </ng-container>
  <ng-container *ngIf="disabled">
   <div class="form__input form__input_has-icon form__input_disabled">
      <div class="input__placeholder input__placeholder_pointer">
        <span *ngIf="checked_option">{{checked_option.caption}}</span>
      </div>
    </div>
  </ng-container>
</div>
<!-- // -->
<select #select [hidden]="true">
  <ng-content></ng-content>
</select>
