import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input, OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';

class Option {
  constructor(public caption: string, public value: string, public checked = false) { }
}


@Component({
  selector: 'app-material-select',
  templateUrl: './material-select.component.html',
  styleUrls: ['./material-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaterialSelectComponent),
      multi: true
    }
  ]
})
export class MaterialSelectComponent implements AfterViewInit, OnInit, OnDestroy {

  @Input() public form: UntypedFormGroup;
  @Input() public field_name;
  @Input() public value = '';
  @Input() public canRemove = false;
  @Input() public caption = '';
  @Input() public require = false;
  @Input() public triggerClick = false;
  @Input() public error = false;
  @Input() public tblHeadStyle = false;
  @Input() public cssClass = '';
  @Input() public placeholder = '';
  @Output() public onSelected: EventEmitter<any> = new EventEmitter();
  @Output() public callBack: EventEmitter<any> = new EventEmitter();

  @ViewChild('select') public select: ElementRef;
  @ViewChild('dropdownWrapper') public wrapper: any;

  public control: UntypedFormControl;
  public formAdded = false;
  public focusing = false;
  public disabled = false;
  public display_dropdown = false;
  public options: Array<Option> = [];
  public checked_option: Option = new Option('', null);

  private _value: any;

  public set current_value(value: any) {
    this._value = value;
    this.propagateChange(this._value);

    const loc_option_selected = this.options.filter(x => x.value === value).pop();
    if (loc_option_selected) {
      loc_option_selected.checked = true;
      this.checked_option = loc_option_selected;
    }
  }

  public get current_value(): any {
    return this._value;
  }

  propagateChange = (_: any) => {};

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
    if (this.form) {
      this.control = new UntypedFormControl(
        {value: this.value, disabled: this.disabled},
        this.require ? [Validators.required] : null
      );
      this.form.addControl(this.field_name, this.control);

      this.formAdded = true;
    }
  }


  public loadOptions() {
    this.options = [];

    for (let i = 0; i < this.select.nativeElement.options.length; i++) {
      const caption = this.select.nativeElement.options[i].innerText,
        value = this.select.nativeElement.options[i].value,
        option = new Option(caption, value, this.current_value === value);

      this.options.push(option);
    }
  }

  ngAfterViewInit() {

    for (let i = 0; i < this.select.nativeElement.options.length; i++) {
      const caption = this.select.nativeElement.options[i].innerText,
        value = this.select.nativeElement.options[i].value,
        option = new Option(caption, value, this.current_value === value);

      this.options.push(option);
    }

    const checked = this.options.filter(x => x.checked);
    if (checked.length) {
      setTimeout(() => this.checked_option = checked.pop(), 10);
    }
  }

  writeValue(value: any) {
    this.current_value = value;
  }

  onChange(value: any) {
    this.current_value = value;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }

  onClose() {
    this.renderer.removeClass(this.wrapper.nativeElement, 'form__group__dropdown_opened');
    this.display_dropdown = false;
  }
  onSelectChange(option: Option) {
    const loc_option = this.options.filter(x => x.checked).pop(),
      loc_option_selected = this.options.filter(x => x.value === option.value && x.caption === option.caption).pop();

    if (loc_option) {
      loc_option.checked = false;
    }

    loc_option_selected.checked = true;

    this.renderer.removeClass(this.wrapper.nativeElement, 'form__group__dropdown_opened');

    this.checked_option = loc_option_selected;
    this.current_value = loc_option_selected.value;
    this.onSelected.emit(loc_option_selected.value);

    this.display_dropdown = false;
  }
  onCallBack(val) {
    this.callBack.emit(val);
  }

  ngOnDestroy() {
    if (this.canRemove) {
      this.form.removeControl(this.field_name);
    }
  }

}
