import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialInputModule } from '../../modules/@material-input';
import {CeleryErrLogsComponent} from './celery-err-logs.component';
import {ModalModule} from '../../modules/@modal';
import {PaginatorModule} from '../../shared/housing-modules/modules';
import {PopoverModule} from '../../modules/@popover/popober.module';
import {RouterModule} from '@angular/router';
import {CeleryErrLogsResolve} from './celery-err-logs.resolve';
import {SharedModule} from '../../shared/shared.module';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialInputModule,
    FormsModule,
    SharedModule,
    PaginatorModule,
    ReactiveFormsModule,
    ModalModule,
    PopoverModule,
  ],
  declarations: [
    CeleryErrLogsComponent,
  ],
  exports: [
    CeleryErrLogsComponent
  ],
  providers: [
    CeleryErrLogsResolve,
  ]
})
export class CeleryErrLogsModule {
}
