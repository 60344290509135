import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, UntypedFormControl} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {AlternativeDatatablesInterface} from '../../shared/alternative-datatables/alternative-datatables.interface';
import {AlternativeDatatablesComponent} from '../../shared/alternative-datatables/alternative-datatables.component';
import {JsonClientService} from '../../shared/json-client';
import {GoBackButtonService} from '../../services/go-back-button.service';
import {BreadcrumbsService} from '../../shared/breadcrumbs/services';
import {NotificationService} from '../../services/notification.service';
import {Subscription} from 'rxjs';


@Component({
  templateUrl: './celery-err-logs.component.html',
  styleUrls: [
    './celery-err-logs.component.scss'
  ]
})
export class CeleryErrLogsComponent
  extends AlternativeDatatablesComponent
  implements AlternativeDatatablesInterface, OnInit, OnDestroy, AfterViewInit {

  public head;

  public modal_show = false;
  public modal_content = '';

  public form: UntypedFormGroup;

  private _subscription: Subscription = new Subscription();

  public count;
  public rows;
  public filter_columns = {};
  public sort: { [key: string]: string } = {};

  public current_page = 1;

  public set data(value) {
    this.count = value['count'];
    this.rows = value['rows'];
  }

  constructor(private router: Router, private fb: UntypedFormBuilder, public activatedRoute: ActivatedRoute,
              public goBack: GoBackButtonService, private b: BreadcrumbsService,
              public http: JsonClientService, private notify: NotificationService) {
    super(router, activatedRoute, fb, http);
  }

  ngOnInit() {
    this.goBack.header = '<h1>Логи ошибок микросервисов</h1>';
    this.b.set([
      {url: '/', caption: 'Главная'},
      {caption: 'Логи ошибок микросервисов'},
    ]);
    this.form = this.fb.group({});
    this.head = [
      {'name': 'create_time', 'value': '', 'caption': 'Дата', 'sort': false, 'input': false},
      {'name': 'task_id', 'value': '', 'caption': 'Task id', 'sort': false, 'input': true},
      {'name': 'input_params', 'value': '', 'caption': 'Вход', 'sort': false, 'input': true},
      {'name': 'task_name', 'value': '', 'caption': 'Функция', 'sort': false, 'input': true},
      {'name': 'traceback', 'value': '', 'caption': 'Ошибка', 'sort': false, 'input': true},
      {'name': 'duration', 'value': '', 'caption': 'Длительность', 'sort': false, 'input': false},
      {
        'name': 'is_too_long', 'value': '', 'caption': 'Подвисла', 'sort': false, 'select': [
          {'caption': 'Да', 'id': 'true'},
          {'caption': 'Нет', 'id': 'false'},
          {'caption': 'Не выбрано', 'id': ''}
        ],
      },
      {'name': 'process_pid', 'value': '', 'caption': 'Процесс', 'sort': false, 'input': true},
      {'name': 'is_need_to_mark', 'value': '', 'caption': 'Отметка', 'sort': false, 'input': false},
    ];
    this.head = this.head.map(x => {
      x.value = this.activatedRoute.snapshot.queryParams[x.name] || x.value;
      return x;
    });
    if (this.activatedRoute.snapshot.queryParams['sort_name']) {
      this.sort[this.activatedRoute.snapshot.queryParams['sort_name']] = this.activatedRoute.snapshot.queryParams['sort_key'];
    }

    for (const el of this.head) {
      this.form.addControl(el.name, new UntypedFormControl(el.value));
    }
    this._subscription.add(this.form.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.onFilterChanges();
    }));
    this.filter = this.activatedRoute.snapshot.queryParams['global_search_string'] || '';
    this.onInitCall();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
    super.ngOnDestroy();
  }

  onFilterChanged(): void {
    this.data = this.activatedRoute.snapshot.data['data'];
    this.current_page = +this.activatedRoute.snapshot.queryParams['page'] || 1;
  }

  onFilterChanges(text = null) {
    if (text !== null) {
      this.filter = text;
    }

    this.filter_columns = {};
    for (const el of this.head) {
      if (!this.form.get(el.name).value.length) {
        continue;
      }
      this.filter_columns[el.name] = this.form.get(el.name).value;
    }
    if (this.filter !== '') {
      this.filter_columns['global_search_string'] = this.filter;
    }
    if (this.sort) {
      this.filter_columns['sort_name'] = Object.keys(this.sort)[0];
      this.filter_columns['sort_key'] = this.sort[this.filter_columns['sort_name']];
    }

    this.goBack.header = '<h1>Логи ошибок микросервисов</h1>';
    this.b.set([
      {url: '/', caption: 'Главная'},
      {caption: 'Логи ошибок микросервисов'},
    ]);
    this.router.navigate(['celery-err-log'], {
      queryParams: this.getQueryParams(),
    });
  }

  getQueryParams() {
    const query_params = {}, cols = [...this.head];
    for (const el of cols) {
      let _val = this.form.get(el.name).value;
      if (!_val.length) {
        continue;
      }
      if (_val === 'true' || _val === 'false') {
        _val = _val === 'true';
      }
      query_params[el.name] = _val;
    }
    if (this.sort) {
      query_params['sort_name'] = Object.keys(this.sort)[0];
      query_params['sort_key'] = this.sort[query_params['sort_name']];
    }
    if (this.filter !== '') {
      query_params['global_search_string'] = this.filter;
    }
    return query_params;
  }

  prepareSort(column_name: string) {
    for (const key in this.sort) {
      if (column_name !== key) {
        delete this.sort[key];
      }
    }
  }

  sortChange(column_name: string) {
    this.prepareSort(column_name);
    this.sort[column_name] = !this.sort[column_name] || ('desc' === this.sort[column_name]) ? 'asc' : 'desc';
    this.onFilterChanges();
  }

  ngAfterViewInit() {
    this.goBack.goScroll();
  }

  viewLogParam(id: any, event) {
    event.stopPropagation();
    this.modal_show = true;
    this.modal_content = id;
  }

  modalClose() {
    this.modal_show = false;
  }

  onMarkChange(row) {
    this.http.post('CeleryErr.set_mark', {
      'log_id': row.id,
      'is_need_to_mark': row.is_need_to_mark
    })
      .then(() => {
        // this.formGroup.patchValue({
        //   'feed_update_time': x,
        // });
        // this.notify.notifySuccess('Успех', `Отметка поставлена в "${row.is_need_to_mark}"`, 2000);
      }, () => {
        row.is_need_to_mark = !row.is_need_to_mark;
      });
    // console.log(row);
  }

}
