import {AfterViewInit, Component, ComponentRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {OverlayWindowService} from '../../../_crm/single-window/shared/overlay-window.service';
import {CalendarCreateInvitesComponent} from '../shared/calendar-create-invites.component';
import {SimpleTableComponent} from '../../../modules/@simple-table/simple-table.component';
import {HttpClient} from '../../../services/http.service';
import {NotificationService} from '../../../services/notification.service';
import {CalendarInviteComponent} from '../invites/calendar-invite-component';
import {DictionariesService} from '../../../services/dictionaries.service';
import {BreadcrumbsService} from '../../breadcrumbs/services';
import {GoBackButtonService} from '../../../services/go-back-button.service';

enum Modification {
  Create,
  Edit
}


@Component({
  templateUrl: './calendar-event.component.html',
  styleUrls: ['./calendar-event.component.scss']
})
export class CalendarEventComponent implements OnInit, AfterViewInit, OnDestroy {

  private readonly itemsPerPage = 15;

  private readonly SPNPlacement = {
    'filename': 'Схема проезда',
    'filepath': '87e2dc33-b7cb-4b60-815f-6e6f1546eb10.jpg',
    'map_latitude': 55.731338,
    'map_longitude': 37.560179,
    'comment': '',
    'how_travel': `От станции МЦК «Лужники»:
из метро налево на ул. Хамовнический вал. На пересечении с улицей Лужнецкий пр-д поверните направо;
далее на пересечении с ул. Новодевичий пр-д поверните налево и идите до пересечения с Саввинской набережной;
далее поверните направо и следуйте по Саввинской набережной прямо до дома 23 корп. 1. Офис будет находится справа от Вас.

От станции м. «Спортивная»:
последний вагон из центра, выход к ул. 10-летия Октября;
выйдя из метро, поверните направо и идите по ул. 10-летия Октября до пересечения с ул. Малая Пироговская (ориентир – вывеска справа «Театр Камбуровой»);
идите прямо до перекрестка с ул. Большая Пироговская. Перейдите по пешеходному переходу и следуйте вниз до набережной мимо Новодевичьего пруда (он будет слева) до Саввинской набережной (не более 5 минут);
далее поверните направо и следуйте по Саввинской набережной прямо до дома 23 стр. 1. Офис будет находится справа от Вас.

От ст. м. «Киевская» кольцевая (20 минут пешком):
выход к Киевскому вокзалу на площадь «Европы»;
дойдите до стеклянного пешеходного моста через Москва-реку и поверните направо (дорога займет около 15 минут);
офис будет находиться слева от Вас.


От ст. м. «Киевская» Филёвской линии:
выход к Киевскому вокзалу на площадь Европы, из стеклянных дверей метро прямо по переходу следуя указателю «К Бережковской набережной»;
идите прямо до моста по направлению к Бережковской набережной (ориентир справа – гостиница «Славянская»);
перейдите по мосту на противоположный берег, спуститесь на Саввинскую набережную;
следуйте по Саввинской набережной прямо до дома 23 корп. 1. Офис будет находиться слева от Вас.

На машине из центра:
Следуйте по Саввинской набережной со стороны центра в направление ТТК. Офис находится не доезжая 50 метров до поворота на Малый
Саввинский переулок.

На машине в центр:
Следуйте по Саввинской набережной со стороны ТТК. На светофоре на пересечении с улицей Новодевичий проезд продолжайте движение
прямо еще 250 метров. Сразу за пересечением с Малым Саввинским переулком, справа от Вас, в здании бизнес-центра находится офис компании.`
  };

  private _current_page = 0;
  private _invitesUsers: Array<{[p: string]: string}> = [];

  private event_id = null;

  public prepared_data = {};

  public event_types: Array<{[p: string]: string}>;
  public invites_templates: Array<{[p: string]: string | number}>;

  public invitesUsersSlice: Array<{[p: string]: string}> = [];

  public form: UntypedFormGroup;
  public modification: Modification = Modification.Create;

  public subscription: Subscription = new Subscription();

  public display_map = false;

  public map_latitude = null;
  public map_longitude = null;

  public event_type_caption = null;

  public backref = '/calendar/list';
  private fragment = null;
  private first_load = true;
  private themeFromCaption = true;

  @ViewChild(SimpleTableComponent, {static: true}) private table: SimpleTableComponent;
  @ViewChild('selectTemplate') private selectTemplate;

  public get current_page(): number {
    return this._current_page;
  }

  public set current_page(value: number) {
    this._current_page = value;
    this.invitesUsersSlice = this._invitesUsers
      .slice(this.current_page * this.itemsPerPage, (this.current_page + 1) * this.itemsPerPage);
  }

  public get invitesUsers(): Array<{ [p: string]: string }> {
    return this._invitesUsers;
  }

  public set invitesUsers(value: Array<{ [p: string]: string }>) {
    this._invitesUsers = value;
    this._current_page = 0;

    this.table.setTotalRows(value.length);
    this.invitesUsersSlice = value.slice(this.current_page * this.itemsPerPage, (this.current_page + 1) * this.itemsPerPage);
  }

  private _showExtendedAnnotation = null;
  public set showExtendedAnnotation(v: boolean) {
    // if (v === false) {
    //   this.form.get('extented_annotation').patchValue('');
    // }
    this._showExtendedAnnotation = v;
  }
  public get showExtendedAnnotation(): boolean {
    if (this._showExtendedAnnotation === null) {
      this._showExtendedAnnotation = !!this.form.get('extented_annotation').value;
    }
    return this._showExtendedAnnotation;
  }

  private _showNotifyDescription = null;
  public set showNotifyDescription(v: boolean) {
    // if (v === false) {
    //   this.form.get('notify_title').patchValue('');
    //   this.form.get('notify_description').patchValue('');
    // }
    this._showNotifyDescription = v;
  }
  public get showNotifyDescription(): boolean {
    if (this._showNotifyDescription === null) {
      this._showNotifyDescription = !!this.form.get('notify_description').value;
    }
    return this._showNotifyDescription;
  }

  constructor(private activatedRoute: ActivatedRoute, private overlayWindowService: OverlayWindowService,
              private fb: UntypedFormBuilder, private http: HttpClient, private notify: NotificationService,
              private goBack: GoBackButtonService,
              private router: Router, private dict: DictionariesService, private b: BreadcrumbsService) { }

  ngOnInit() {
    /* Prepare old data if modification required */
    this.b.set([
      {url: '/', caption: 'Главная'},
      {url: '/calendar/list', caption: 'Календарь событий'},
    ]);

    const data = this.activatedRoute.snapshot.data['data'];

    if (data) {
      this.prepared_data = data;
      this.event_id = data['event_id'];
      this.invitesUsers = data['invites'];
      this.modification = Modification.Edit;
    }

    if (this.activatedRoute.snapshot.queryParams['backref']) {
      this.backref = this.activatedRoute.snapshot.queryParams['backref'];
    }

    this.fragment = this.activatedRoute.snapshot.fragment;

    /* Loading dictionaries data */
    this.event_types = this.activatedRoute.snapshot.data['types'].map(x => {
      x.type_id = x.type_id.toString();
      return x;
    });
    this.invites_templates = this.activatedRoute.snapshot.data['invites'];
    this.form = this.fb.group({
      'caption': [this.prepared_data['caption'] || '', Validators.required],
      'type': [this.prepared_data['type'] || '', Validators.required],
      'participants_cnt': [this.prepared_data['participants_cnt'] || '', Validators.required],
      'event_date': [this.prepared_data['event_date'] || '', Validators.required],
      'event_time_begin': [this.prepared_data['event_time_begin'] || '', Validators.required],
      'event_time_end': [this.prepared_data['event_time_end'] || '', Validators.required],
      'annotation': [this.prepared_data['annotation'] || '', Validators.required],
      'extented_annotation': [this.prepared_data['extented_annotation'] || ''],
      'notify_title': [this.prepared_data['notify_title'] || ''],
      'notify_description': [this.prepared_data['notify_description'] || ''],
      'notify_url': [this.prepared_data['notify_url'] || ''],
      'register_finish': [this.prepared_data['event_register_finish'] || false],
      'image1': [this.prepared_data['image1'] || ''],
      'image2': [this.prepared_data['image2'] || ''],
      'image3': [this.prepared_data['image3'] || ''],
      'image4': [this.prepared_data['image4'] || ''],
      'complexes_id': [
        this.prepared_data['event_complexes'] && this.prepared_data['event_complexes'].length
          ? this.prepared_data['event_complexes'].map(x => '' + x['complex_id'])  : []
      ],
      'placement_spn': [this.prepared_data['placement_spn'] || '0'],
      'address': [this.prepared_data['address'] || '', Validators.required],
      'placement_map': [this.prepared_data['placement_map'] || ''],
      'placement_description': [this.prepared_data['placement_description'] || ''],
      'template_current': [this.prepared_data['template_current'] || ''],
      'template_sms_text': [this.prepared_data['template_sms_text'] || ''],
      'template_email_subj': [{value: this.prepared_data['template_email_subj'] || '', disabled: true}],
      'template_email_subj_dop': [this.prepared_data['template_email_subj_dop'] || ''],
      'template_email_text': [this.prepared_data['template_email_text'] || ''],
      'notify_now': [this.prepared_data['notify_now'] || false],
      'notify_2_hours': [Object.keys(this.prepared_data).indexOf('notify_2_hours') !== -1 ? this.prepared_data['notify_2_hours'] : true],
      'notify_1_day': [Object.keys(this.prepared_data).indexOf('notify_1_day') !== -1 ? this.prepared_data['notify_1_day'] : true],
      'end_notification': [Object.keys(this.prepared_data).indexOf('end_notification') !== -1
        ? this.prepared_data['end_notification']
        : true],
    });

    if (
      this.form.controls['template_email_subj_dop'].value &&
      this.form.controls['caption'].value !== this.form.controls['template_email_subj_dop'].value
    ) {
      this.themeFromCaption = false;
    }

    const updateTypeCaption = (selectedValue) => {
      this.event_type_caption = selectedValue ? this.event_types
        .filter(x => x['type_id'] === selectedValue)
        .pop()['type_caption'] : '';
    }, updateEmailSubj = () => {
      const subjDopVal = (this.form.controls['template_email_subj_dop'].value || '').trim();
      this.form.controls['template_email_subj'].setValue(
        'СПН24: ' +
        this.event_type_caption + ' ' + this.form.controls['event_date'].value +
        ' в ' + this.form.controls['event_time_begin'].value + (subjDopVal ? ' «' + subjDopVal + '»' : '')
      );
    };
    updateTypeCaption(this.form.controls['type'].value);

    this.subscription.add(this.form.controls['address'].valueChanges.subscribe(selectedValue => {
      const {lat, lon} = selectedValue;

      this.map_latitude = parseFloat(lat);
      this.map_longitude = parseFloat(lon);
    }));

    this.subscription.add(this.form.controls['caption'].valueChanges.subscribe(selectedValue => {
      if (this.themeFromCaption === true) {
        this.form.controls['template_email_subj_dop'].patchValue(selectedValue, {onlySelf: true, emitEvent: false});
        updateEmailSubj();
      }
    }));

    this.subscription.add(this.form.controls['event_date'].valueChanges.subscribe(_ => {
      updateEmailSubj();
    }));

    this.subscription.add(this.form.controls['event_time_begin'].valueChanges.subscribe(_ => {
      updateEmailSubj();
    }));

    this.subscription.add(this.form.controls['template_email_subj_dop'].valueChanges.subscribe(selectedValue => {
      this.themeFromCaption = selectedValue === this.form.controls['caption'].value;
      updateEmailSubj();
    }));


    this.subscription.add(this.form.get('placement_spn').valueChanges.subscribe(selectedValue => {

      if ('1' === selectedValue) {
        this.form.controls['address'].disable({emitEvent: false, onlySelf: true});
        this.form.controls['placement_description'].disable({emitEvent: false, onlySelf: true});
        this.form.patchValue({
          'placement_map': this.SPNPlacement,
          'placement_description': this.SPNPlacement['how_travel'],
        }, {onlySelf: true, emitEvent: false});

        this.map_latitude = this.SPNPlacement['map_latitude'];
        this.map_longitude = this.SPNPlacement['map_longitude'];
      } else {
        if (!this.first_load) {
          this.form.controls['address'].enable({emitEvent: false, onlySelf: true});
          this.form.controls['placement_description'].enable({emitEvent: false, onlySelf: true});
          this.form.patchValue({
            'placement_map': '',
            'placement_description': '',
          }, {onlySelf: true, emitEvent: false});
          this.map_latitude = null;
          this.map_longitude = null;
        }
      }
      this.first_load = false;
    }));

    this.subscription.add(this.form.controls['type'].valueChanges.subscribe(selectedValue => {
      updateTypeCaption(selectedValue);
      updateEmailSubj();
      const event_temp_assoss = {
        '1': '12', // Обучающий семинар
        '2': '2', // Инфо-тур
        '3': '3', // Вебинар
        '4': '5', // Встреча с застройщиком
        '5': '4', // Тренинг
        '6': '7', // Встреча руководителей (Бизнес клуб)
        '7': '6', // Конференция
        '8': '2', // Брокер-тур
      };
      if (event_temp_assoss[selectedValue] !== undefined) {
        this.form.get('template_current').setValue(event_temp_assoss[selectedValue]);
      } else {
        this.form.get('template_current').setValue('2');
      }
      if ('3' === selectedValue) {
        this.form.get('placement_spn').setValue('1');
      }
    }));

    this.subscription.add(this.form.get('template_current').valueChanges.subscribe(selectedValue => {
      const index = this.invites_templates.findIndex(x => x['template_id'] === +selectedValue);

      if (-1 !== index) {
        this.form.patchValue({
          'template_sms_text': this.invites_templates[index]['sms_text'],
          'template_email_text': this.invites_templates[index]['email_text'],
        }, {onlySelf: true, emitEvent: false});
      }
    }));

    if (this.invites_templates.length > 0 && this.modification === Modification.Create) {
      this.form.patchValue({
        'template_current': this.invites_templates[0]['template_id'].toString()
      });
    }

    if (this.modification === Modification.Edit) {
      this.form.patchValue({
        'placement_spn': this.prepared_data['placement_spn'],
        'notify_now': false,
      });

      this.form.get('notify_now').disable();
    }


    if (this.modification) {
      this.goBack.header = '<h1>Редактирование события</h1>';
    } else {
      this.goBack.header = '<h1>Создание события</h1>';
    }
  }

  ngAfterViewInit() {
    const anchor_element = document.querySelector(`#${this.fragment}`);

    if (anchor_element) {
      anchor_element.scrollIntoView();
    }

    const scrolledY = window.scrollY;

    if (scrolledY) {
      window.scroll(0, scrolledY - 60);
    }
  }

  addParticipants() {
    this.overlayWindowService.openComponentInPopup(CalendarCreateInvitesComponent)
      .then((componentRef: ComponentRef<CalendarCreateInvitesComponent>) => {
        let event_type = null;

        if (this.prepared_data['type']) {
          event_type = this.event_types
                      .filter(x => x['type_id'].toString() === this.prepared_data['type'].toString())
                      .pop();
        }

        componentRef.instance.invitesUsers = this.invitesUsers;
        componentRef.instance.event_data = {
          'caption': this.prepared_data['caption'],
          'event_date': this.prepared_data['event_date'],
          'type': event_type,
        };
        const subscription: Subscription = componentRef.instance.invitesEmitter.subscribe(x => {
          this.invitesUsers = x;
          subscription.unsubscribe();
        });
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  displayMap() {
    if (!this.map_latitude || !this.map_longitude) {
      return;
    }

    this.display_map = true;
  }

  exportReport() {
    const exp_list = [];

    for (let i = 0; i < this.invitesUsers.length; i++) {
      exp_list.push({
        'num': i + 1,
        'name': this.invitesUsers[i]['physical_fio'],
        'phone': this.invitesUsers[i]['physical_phone'],
        'email': this.invitesUsers[i]['physical_email'],
      });
    }

    const header = ['№', 'ФИО', 'Телефон', 'Email'];
    const cols = ['num', 'name', 'phone', 'email'];
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const csv = exp_list.map(row => cols.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(';'));
    csv.unshift(header.join(';'));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a'),
      blob = new Blob(['\ufeff' + csvArray], { type: 'text/csv;charset=utf-8;' }),
      url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = 'invites.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  exclude(row) {
    const index = this.invitesUsers.findIndex(x => x.physical_id === row.physical_id);

    if (-1 !== index) {
      this.invitesUsers.splice(index, 1);
      this.table.setTotalRows(this._invitesUsers.length);
      this.invitesUsersSlice = this._invitesUsers
        .slice(this.current_page * this.itemsPerPage, (this.current_page + 1) * this.itemsPerPage);
    }
  }

  submit() {
    // if (this.showExtendedAnnotation === false) {
    //   this.form.get('extented_annotation').patchValue('');
    // }
    // if (this.showNotifyDescription === false) {
    //   this.form.get('notify_title').patchValue('');
    //   this.form.get('notify_description').patchValue('');
    // }
    const method = this.modification === Modification.Create ? 'Calendar.add_event' : 'Calendar.modify_event',
      data = JSON.parse(JSON.stringify(this.form.getRawValue()));

    /* Destroy invite_template_id in modification mode */

    if (this.modification === Modification.Edit) {
      delete data['template_current'];
      data['event_id'] = this.event_id;
    }

    data['invites'] = this._invitesUsers;

    this.http.post(method, data)
      .subscribe(event_id => {
        this.notify.notifySuccess('Поздравляем', 'Данные успешно сохранены', 3000);

        if (this.modification === Modification.Create) {
          this.router.navigateByUrl(`/calendar/edit/${event_id}`);
        }
      });
  }

  createTemplate() {
    this.overlayWindowService.openComponentInPopup(CalendarInviteComponent)
      .then((componentRef: ComponentRef<CalendarInviteComponent>) => {
        componentRef.instance.invite_id = null;
        const subscription: Subscription = componentRef.instance.saveEmitter.subscribe(_ => {
          this.overlayWindowService.closeHost();
          this.dict.unset('Calendar.invite_templates_list');
          this.dict.get('Calendar.invite_templates_list').then(x => {
            this.invites_templates = x;
            setTimeout(__ => this.selectTemplate.loadOptions(), 100);
          });
          this.notify.notifySuccess('Поздравляем', 'Новый шаблон успешно сохранен', 3000);
        });
      });
  }

  removeEvent() {
    NotificationService.swalConfirm('Удалить?', 'Мероприятие будет удалено, продолжить?')
      .then(_ => {
        this.http.post('Calendar.calendar_remove_event', {'event_id': this.event_id})
          .subscribe(__ => {
            this.router.navigateByUrl('/calendar/list');
          });
      }).catch(() => false);
  }

}
