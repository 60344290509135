<div class="row wrapper mt-4" id="top">
  <div class="col">
    <form [formGroup]="form" class="form form_crm" (submit)="submit()">
      <div class="row">
        <div class="col-md-7">
          <app-material-input [require]="true" formControlName="caption">Название</app-material-input>
        </div>
        <div class="col-md-5">
          <app-material-select formControlName="type" [caption]="'Тип события'" [require]="true">
            <option *ngFor="let option of event_types" [value]="option['type_id']">
              {{option['type_caption']}}</option>
          </app-material-select>
        </div>
        <div class="col-md-3">
          <app-material-input formControlName="participants_cnt" [require]="true">
            Кол-во участников</app-material-input>
        </div>
        <div class="col-md-3">
          <app-material-input-datepicker formControlName="event_date" [require]="true">
            Дата проведения</app-material-input-datepicker>
        </div>
        <div class="col-md-3">
          <app-material-input-mask formControlName="event_time_begin" [require]="true"
                                   [placeholder]="'__:__'" [mask]="'00:00'">
            Время начала</app-material-input-mask>
        </div>
        <div class="col-md-3">
          <app-material-input-mask formControlName="event_time_end" [require]="true"
                                   [placeholder]="'__:__'" [mask]="'00:00'">
            Время окончания</app-material-input-mask>
        </div>
        <div class="col-md-8">
          <app-material-select2-autocomplete
            [formControlName]="'complexes_id'"
            [multiple]="true"
            [caption]="'Жилые комплексы'"
            [api_action]="'Dict.housing_complexes_search'">
              <ng-container *ngIf="prepared_data['event_complexes'] && prepared_data['event_complexes'].length">
                <option *ngFor="let el of prepared_data['event_complexes']"
                  [attr.value]="el['complex_id']">
                    {{el['complex_caption']}}
                </option>
              </ng-container>
              <ng-container *ngIf="!prepared_data['event_complexes'] || !prepared_data['event_complexes'].length">
                <option value=""></option>
              </ng-container>
          </app-material-select2-autocomplete>
        </div>
        <div class="col-md-4 pt-4">
          <app-material-input-checkbox formControlName="register_finish">Регистрация завершена</app-material-input-checkbox>
        </div>
      </div>
      <h1>Описание</h1>
      <div class="mb-4">
        <app-wysiwyg-editor formControlName="annotation" [require]="true">Аннотация</app-wysiwyg-editor>
      </div>
      <div class="mb-4">
        <div class="mb-3">
          <a href="javascript:void(0)"
             (click)="showExtendedAnnotation = !showExtendedAnnotation"
          >
            <i class="fa"
               [class.fa-chevron-down]="showExtendedAnnotation === false"
               [class.fa-chevron-up]="showExtendedAnnotation === true"
            ></i>
            Расширенное описание
          </a>
        </div>

        <app-wysiwyg-editor
          *ngIf="showExtendedAnnotation === true"
          formControlName="extented_annotation"
        ></app-wysiwyg-editor>
      </div>
      <div class="mb-4">
        <div class="mb-3">
          <a href="javascript:void(0)"
             (click)="showNotifyDescription = !showNotifyDescription"
          >
            <i class="fa"
               [class.fa-chevron-down]="showNotifyDescription === false"
               [class.fa-chevron-up]="showNotifyDescription === true"
            ></i>
            Уведомление в ЛК СА
          </a>
        </div>

        <ng-container *ngIf="showNotifyDescription === true || form.get('type').value === '3'">
          <div class="mb-3">
            <app-material-input
              formControlName="notify_title"
              [maxLength]="15"
            >Текст в хедере ЛК СА ({{form.get('notify_title').value.length}}/15 символов)
            </app-material-input>
          </div>
          <div class="mb-3">
            <app-material-input
              formControlName="notify_url"
              [require]="form.get('type').value === '3'"
            >Ссылка для кнопки «Подключиться»
            </app-material-input>
          </div>
          <div class="mb-3">
            <app-wysiwyg-editor
              formControlName="notify_description"
            >Страница уведомления в ЛК СА
            </app-wysiwyg-editor>
          </div>
        </ng-container>
      </div>
      <div class="row">
        <div class="col-md-3">
          <app-material-input-dropimage
                  formControlName="image1"
                  [cssClass]="'m-h-none embed-responsive embed-responsive-1by1 visible'"
                  [cssClassZone]="'zone-photo'">
            Перетащите сюда фотографию или иллюстрацию
          </app-material-input-dropimage>
        </div>
        <div class="col-md-3">
          <app-material-input-dropimage
                  formControlName="image2"
                  [cssClass]="'m-h-none embed-responsive embed-responsive-1by1 visible'"
                  [cssClassZone]="'zone-photo'">
            Перетащите сюда фотографию или иллюстрацию
          </app-material-input-dropimage>
        </div>
        <div class="col-md-3">
          <app-material-input-dropimage
                  formControlName="image3"
                  [cssClass]="'m-h-none embed-responsive embed-responsive-1by1 visible'"
                  [cssClassZone]="'zone-photo'">
            Перетащите сюда фотографию или иллюстрацию
          </app-material-input-dropimage>
        </div>
        <div class="col-md-3">
          <app-material-input-dropimage
                  formControlName="image4"
                  [cssClass]="'m-h-none embed-responsive embed-responsive-1by1 visible'"
                  [cssClassZone]="'zone-photo'">
            Перетащите сюда фотографию или иллюстрацию
          </app-material-input-dropimage>
        </div>
      </div>
      <ng-container *ngIf="form.value['type'] !== '3'">
        <h1>Место проведения</h1>
        <div class="row form-group">
          <div class="col-md-4">
            <div class="btn-group button-group">
              <label class="button button_grey" [ngClass]="{'button_active': form.value['placement_spn'] === '1'}">
                Офис СПН24 <input type="radio" value="1" formControlName="placement_spn"></label>
              <label class="button button_grey"  [ngClass]="{'button_active': form.value['placement_spn'] !== '1'}">
                Другое <input type="radio" value="0" formControlName="placement_spn"></label>
            </div>
          </div>
        </div>
<!--        <p class="text-right check-map">-->
<!--          <a href="javascript:void(0)" (click)="displayMap()">-->
<!--            Проверить по Яндексу</a>-->
<!--        </p>-->
        <app-input-fias-event formControlName="address" [required]="true">Адрес</app-input-fias-event>
        <p [hidden]="!form.get('address').disabled">Будет использован адрес: <b>г. Москва, Саввинская наб., д. 23 стр. 1</b></p>
        <div class="row form-group">
          <div class="col-md-9">
            <app-material-textarea
              formControlName="placement_description"
              [cssClass]="'maximized-form'">
              Как добраться
            </app-material-textarea>
          </div>
          <div class="col-md-3 map-scheme">
            <div class="form__label">Схема проезда</div>
            <app-material-input-dropimage
              formControlName="placement_map"
              [cssClass]="'m-h-none embed-responsive embed-responsive-1by1 visible'"
              [cssClassZone]="'zone-photo'">
              Перетащите сюда картинку схемы проезда
            </app-material-input-dropimage>
          </div>
        </div>
      </ng-container>
      <h1>Участники</h1>
      <div class="participants-buttons" id="participants">
        <button class="button" type="button" (click)="addParticipants()">Добавить участника</button>
        <button class="button" type="button" [hidden]="!invitesUsers.length" (click)="exportReport()">Экспортировать</button>
      </div>
      <p [hidden]="invitesUsersSlice.length">В этом мероприятии еще нет участников</p>
      <app-simple-table [hidden]="!invitesUsersSlice.length" (currentPageEmitter)="current_page = $event">
        <app-simple-table-th>ФИО</app-simple-table-th>
        <app-simple-table-th>Организация</app-simple-table-th>
        <app-simple-table-th>Телефон</app-simple-table-th>
        <app-simple-table-th>Эл. почта</app-simple-table-th>
        <app-simple-table-th></app-simple-table-th>
        <!-- rows -->
        <app-simple-table-tr *ngFor="let row of invitesUsersSlice; index as i" [template]="template">
          <ng-template #template>
            <td><a href="javascript:void(0)">{{row['physical_fio']}}</a></td>
            <td>{{row['organisation_caption']}} <a target="_blank" [routerLink]="['/entity/edit', row['organisation_id'], 'physical']">перейти</a></td>
            <td>
              <ng-container *ngIf="row['physical_notify_email']">{{row['physical_phone']}}</ng-container>
              <ng-container *ngIf="!row['physical_notify_sms']">
                <div>{{row['physical_phone']}}</div>
                <div class="text-danger">Оповещения отключены</div>
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="row['physical_notify_email']">{{row['physical_email']}}</ng-container>
              <ng-container *ngIf="!row['physical_notify_email']">
                <div>{{row['physical_email']}}</div>
                <div class="text-danger">Оповещения отключены</div>
              </ng-container>
            </td>
            <td>
              <a href="javascript:void(0)" (click)="exclude(row)">Исключить</a>
            </td>
          </ng-template>
        </app-simple-table-tr>
      </app-simple-table>
      <div class="invites">
        <div class="row">
          <div class="col-md-6">
            <h1>Приглашение</h1>
          </div>
          <div class="col-md-4 ml-lg-auto">
            <app-material-select formControlName="template_current" [caption]="'Подставить шаблон'" #selectTemplate>
              <option *ngFor="let option of invites_templates" [value]="option['template_id']">
                {{option['caption']}}</option>
            </app-material-select>
            <p><a href="javascript:void(0)" (click)="createTemplate()">Создать шаблон</a></p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8">
            <app-material-input formControlName="template_email_subj">Тема будущего письма</app-material-input>
            <app-material-input formControlName="template_email_subj_dop">Дополнение темы e-mail</app-material-input>
            <app-material-textarea formControlName="template_email_text">Текст e-mail</app-material-textarea>
          </div>
          <div class="col-md-4">
            <app-material-textarea formControlName="template_sms_text">Текст SMS</app-material-textarea>
          </div>
        </div>
      </div>
      <div class="notifications">
        <h1>Уведомления</h1>
        <div class="row">
          <div class="col-sm-3" *ngIf="form.controls['notify_now'].enabled">
            <app-material-input-checkbox formControlName="notify_now">Уведомлять моментально</app-material-input-checkbox>
          </div>
          <div class="col-sm-3">
            <app-material-input-checkbox formControlName="notify_2_hours">Уведомлять за 2 часа</app-material-input-checkbox>
          </div>
          <div class="col-sm-3">
            <app-material-input-checkbox formControlName="notify_1_day">Уведомлять за сутки</app-material-input-checkbox>
          </div>
          <div class="col-sm-3">
            <app-material-input-checkbox formControlName="end_notification">Уведомлять при закрытии</app-material-input-checkbox>
          </div>
        </div>
      </div>
      <button type="submit" class="button" [disabled]="!form.valid">Сохранить</button>
    </form>
  </div>
</div>

<app-modal *ngIf="display_map && null !== map_latitude && null !== map_longitude" [closeOutside]="false"
           (onClosed)="display_map = false">
  <div class="map-wrapper">
    <!-- default map -->
<!--    <osm-map [center]="[map_latitude, map_longitude]">-->
<!--      <osm-placemark-->
<!--        [coordinates]="[map_latitude, map_longitude]">-->
<!--      </osm-placemark>-->
<!--    </osm-map>-->
  </div>
</app-modal>
