import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {JsonClientService} from '../../shared/json-client';

@Injectable()
export class CeleryErrLogsResolve implements Resolve<any> {


  constructor(private jsonRpc: JsonClientService) { }

  private filter_columns =
    [
      'create_time',
      'task_id',
      'input_params',
      'task_name',
      'is_too_long',
      'return_val',
      'traceback',
      'duration',
      'process_pid',
      'is_need_to_mark',
    ];

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {

    const prepared_filter = {};
    const prepared_filter_keys = this.filter_columns.filter(x => !!route.parent.queryParams[x]);
    const sort = {};

    prepared_filter_keys.forEach(x => prepared_filter[x] = route.parent.queryParams[x]);

    if (!!route.queryParams['sort_name']) {
      sort[route.queryParams['sort_name']] = route.queryParams['sort_key'] || 'asc';
    }
    const options = {
      'limit': 50,
      'offset': (+(route.queryParams['page'] || 1) - 1) * 50,
      '_filter': {
        'global_search_string': route.queryParams['global_search_string'] || null,
        'fields': prepared_filter,
      },
      'sort': sort,
    };

    return this.jsonRpc.post('CeleryErr.get_logs', options);
  }
}
